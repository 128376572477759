import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const Login = () => {
    return (
     <React.Fragment>
        <Header name={{ name: 'Welcome to React' }}   />
        <div className="contentswrap">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="form-wrap-titles login">
                    <h1>Enter your user ID and password to log in</h1>
                    <p>If you don't have an account,<a href="register"> Create an account now</a></p>
                </div>
                <div className="form-wrap register login">
                    <form autocomplete="off">
                        <div className="row">
                            <div className="form-floating mb-3 col-md-12" >
                                <input type="text"  autoComplete='false' className="form-control name" id="" placeholder="Register Form"/>
                                <label htmlFor="floatingInput">Phone / ICPC</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-floating mb-3 col-md-12" >
                                <input type="password" autoComplete='false'   className="form-control password" id="" placeholder="Register Form"/>
                                <label for="floatingInput">Password</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3 form-check">
                                    <input type="checkbox" className="form-check-input" id=""/>
                                    <label className="form-check-label" for="exampleCheck1">Remember my user ID</label>
                                </div>
                            </div>

                            <div className="col-12 text-center">
                                <button type="submit" className="btn_register">Login</button>
                                <a href="" className="forgot-password">Forgot username and password</a>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>

</div>
        <Footer />
    </React.Fragment>
    );
  }
  
  export default Login;
  