import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const AboutUs = () => {
    return (
      <React.Fragment>
        <Header name={{ name: "Welcome to React" }} />
        <div className="contentswrap">
          <section
            className="banner-wrapper"
            style={{
              backgroundImage: `url(${"assets/img/aboutus-banner.png"})`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="banner-title">
                    <h1>About FedEx</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap bann">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">HOME</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        ABOUT US
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-titles about">
                  <h1></h1>
                  <p></p>
                </div>
              </div>
            </div>
          </section>
          <section className="about-tab">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="about-tad-wrap">
                    <nav className="abouttabs">
                      <div
                        className="nav nav-tabs "
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#tab1"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          <img src="assets/img/tab-image1.png" alt=""></img>
                          <h2>FACTS</h2>
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#tab2"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          <img src="assets/img/tab-image2.png" alt=""></img>
                          <h2>FACTS</h2>
                        </button>
                        <button
                          className="nav-link"
                          id="nav-contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#tab3"
                          type="button"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="false"
                        >
                          <img src="assets/img/tab-image3.png" alt=""></img>
                          <h2>FACTS</h2>
                        </button>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      {/* <!-- Tab 1 --> */}
                      <div
                        className="tab-pane fade show active"
                        id="tab1"
                        role="tabpanel"
                      >
                        <div className="tabinner-wrap">
                          <div className="tabinner-col1">
                            <img
                              src="assets/img/advantis-express.png"
                              alt=""
                              className="img-fluid"
                            ></img>
                          </div>
                          <div className="tabinner-col2">
                            <div className="tabinner-top">
                              <h2>Legal Name:</h2>
                              <h3>
                                Federal Express Canada Corporation <br></br>
                                Began Operations in 1987 as Federal Express
                                Canada Ltd.
                              </h3>
                            </div>
                            <div className="tabinner-middle">
                              <div className="middle-col1">
                                <img
                                  src="assets/img/ruwan-waidyaratne.jpg"
                                  alt=""
                                  className="img-fluid"
                                ></img>
                                <div className="chairman-title">
                                  <h4>Ruwan Waidyaratne</h4>
                                  <h5>Managing Director - Advantis</h5>
                                </div>
                              </div>
                              <div className="middle-col2">
                                <div className="headlocation">
                                  <h5>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sri
                                    Lankan headquarters:
                                  </h5>
                                  <p>
                                    No: 400, Deans Road, Colombo 10, Sri Lanka
                                    <br></br>
                                    <a href="tel:+94 11 2 627 000">
                                      +94 11 2 627 000
                                    </a>
                                    <br></br>
                                    <a href="mailto:info@cau.hayleys.com">
                                      info@cau.hayleys.com
                                    </a>
                                  </p>
                                </div>
                                <div className="webwrap">
                                  <h6>Website</h6>
                                  <div className="sitelink-wrap">
                                    <img
                                      src="assets/img/globe.svg"
                                      alt=""
                                    ></img>
                                    <a
                                      href="https://hayleysadvantis.com/"
                                      target="_blank"
                                    >
                                      hayleysadvantis.com
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tabinner-bottom">
                              <div className="tab-bottom-col col1">
                                <div className="sub-wrap">
                                  <div className="sub-titles text-center">
                                    <p>
                                      Advantis stands as a distinguished
                                      transportation and logistics powerhouse,
                                      boasting a rich legacy spanning over six
                                      decades. With a robust presence across key
                                      nations, including Bangladesh, India,
                                      Indonesia, the Maldives, Myanmar,
                                      Singapore, Sri Lanka, and Thailand, our
                                      extensive operations have solidified our
                                      position as a regional leader. As a proud
                                      member of the esteemed multinational
                                      conglomerate, Hayleys PLC, Advantis
                                      combines heritage with innovation,
                                      propelling the transportation and
                                      logistics sectors into new frontiers.
                                    </p>
                                    <p>
                                      Our commitment to excellence is evident in
                                      our comprehensive suite of services, which
                                      encompasses Integrated Logistics,
                                      International Freight Management, Marine
                                      and Energy, Projects and Engineering, as
                                      well as Travel and Aviation. This holistic
                                      approach allows us to provide end-to-end
                                      solutions that meet the evolving needs of
                                      our diverse clientele.
                                    </p>
                                    <p>
                                      The synergy between Advantis and Hayleys
                                      PLC not only underscores our financial
                                      stability but also amplifies our capacity
                                      to deliver world-class services. Our
                                      unwavering dedication to quality has been
                                      acknowledged with the prestigious title of
                                      'Best Transportation and Logistics Company
                                      in South Asia' by Global Brands Magazine,
                                      a testament to our unparalleled commitment
                                      to service excellence.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Tab 1 end -->
                            <!-- Tab 2 --> */}
                      <div className="tab-pane fade" id="tab2" role="tabpanel">
                        <div className="tabinner-wrap">
                          <div className="tabinner-col1">
                            <img
                              src="assets/img/Hayleys_logo.png"
                              alt=""
                              className="img-fluid"
                            ></img>
                          </div>
                          <div className="tabinner-col2">
                            <div className="tabinner-top">
                              <h2>Legal Name:</h2>
                              <h3>
                                Federal Express Canada Corporation <br></br>
                                Began Operations in 1987 as Federal Express
                                Canada Ltd.
                              </h3>
                            </div>
                            <div className="tabinner-middle">
                              <div className="middle-col1">
                                <img
                                  src="assets/img/chairman.png"
                                  alt=""
                                  className="img-fluid"
                                ></img>
                                <div className="chairman-title">
                                  <h4>Mohan Pandithage</h4>
                                  <h5>Chairman & Chief Executive</h5>
                                </div>
                              </div>
                              <div className="middle-col2">
                                <div className="headlocation">
                                  <h5>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sri
                                    Lankan headquarters:
                                  </h5>
                                  <p>
                                    No: 400, Deans Road, Colombo 10, Sri Lanka
                                    <br></br>
                                    <a href="tel:+94 11 2 627 000">
                                      +94 11 2 627 000
                                    </a>
                                    <br></br>
                                    <a href="mailto:info@cau.hayleys.com">
                                      info@cau.hayleys.com
                                    </a>
                                  </p>
                                </div>
                                <div className="webwrap">
                                  <h6>Website</h6>
                                  <div className="sitelink-wrap">
                                    <img
                                      src="assets/img/globe.svg"
                                      alt=""
                                    ></img>
                                    <a
                                      href="https://www.hayleys.com/"
                                      target="_blank"
                                    >
                                      www.hayleys.com
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tabinner-bottom">
                              <div className="tab-bottom-col col1">
                                <div className="sub-wrap">
                                  <div className="sub-titles text-center">
                                    <p>
                                      The Hayleys Group is a leading diversified
                                      conglomerate in Sri Lanka, and one of the
                                      country’s largest value-added exporters.
                                      Hayleys accounts for approximately 5.5% of
                                      Sri Lanka’s export income, 4.6% of its tea
                                      and 4.7% of its rubber production, with
                                      business interests in 16 diverse sectors,
                                      catering to over 80 markets worldwide.
                                      Hayleys commands globally leading market
                                      positions in several key sectors, such as
                                      transportation and logistics, agriculture
                                      and retail, accounts for 12% of global
                                      demand for household and industrial gloves
                                      and is the world’s largest manufacturer of
                                      coconut-shell based activated carbon.
                                      Through a vibrant history of 145 years,
                                      Hayleys has nurtured deep-rooted
                                      relationships across the country’s
                                      agricultural and smallholder supply
                                      chains, distribution networks and customer
                                      segments, delivering significant
                                      socio-economic value to its diverse
                                      stakeholders.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Tab 2 end --> */}

                      {/* <!-- Tab 3 --> */}
                      <div className="tab-pane fade" id="tab3" role="tabpanel">
                        <div className="tabinner-wrap">
                          <div className="tabinner-col1">
                            <img
                              src="assets/img/AdvantisExpress(Pvt)Ltd_Logo_3c_Pos_RGB.png"
                              alt=""
                              className="img-fluid"
                            ></img>
                          </div>
                          <div className="tabinner-col2">
                            <div className="tabinner-top">
                              <h2>Legal Name:</h2>
                              <h3>
                                Federal Express Canada Corporation <br></br>
                                Began Operations in 1987 as Federal Express
                                Canada Ltd.
                              </h3>
                            </div>
                            <div className="tabinner-middle">
                              <div className="middle-col1">
                                <img
                                  src="assets/img/Asanka-Weerasinghe.jpg"
                                  alt=""
                                  className="img-fluid"
                                ></img>
                                <div className="chairman-title">
                                  <h4>Asanka Weerasinghe,</h4>
                                  <h5>COO – Advantis Express Pvt Ltd</h5>
                                </div>
                              </div>
                              <div className="middle-col2">
                                <div className="headlocation">
                                  <h5>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sri
                                    Lankan headquarters:
                                  </h5>
                                  <p>
                                    No: 400, Deans Road, Colombo 10, Sri Lanka
                                    <br></br>
                                    <a href="tel:+94 11 2 627 000">
                                      +94 11 2 627 000
                                    </a>
                                    <br></br>
                                    <a href="mailto:info@cau.hayleys.com">
                                      info@cau.hayleys.com
                                    </a>
                                  </p>
                                </div>
                                <div className="webwrap">
                                  <h6>Website</h6>
                                  <div className="sitelink-wrap">
                                    <img
                                      src="assets/img/globe.svg"
                                      alt=""
                                    ></img>
                                    <a href="/home" target="_blank">
                                      advantisexpress.com
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tabinner-bottom">
                              <div className="tab-bottom-col col1">
                                <div className="sub-wrap">
                                  <div className="sub-titles text-center">
                                    <p>
                                      Advantis Express Private Limited is the
                                      Licensee of Federal Express Corporation in
                                      Sri Lanka and the Maldives. Advantis
                                      Express has been in operation for over 30
                                      years in Sri Lanka and is a fully owned
                                      subsidiary of the Advantis Group, the
                                      transportation, logistics and construction
                                      arm of Hayleys PLC.
                                      <br></br>
                                      FedEx Express invented express
                                      transportation and remains the industry’s
                                      global leader, providing rapid, reliable,
                                      time-definite delivery to more than 220
                                      countries and territories, connecting
                                      markets that comprise more than 99% of the
                                      world’s gross domestic product. Unmatched
                                      air route authorities and transportation
                                      infrastructure, combined with leading-edge
                                      information technologies, make FedEx
                                      Express the world’s largest express
                                      transportation company.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- Tab 3 end --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
  
  export default AboutUs;
  