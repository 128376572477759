import Home from "./pages/Home";
import Register from "./pages/Register";
import Agreement from "./pages/Agreement";
import Login from "./pages/Login";
import ShippingRates from "./pages/ShippingRates";
import SchedulePickup from "./pages/SchedulePickup";
import CustomerSupport from "./pages/CustomerSupport";
import ForgotPassword from "./pages/ForgotPassword";
import ContactUs from "./pages/ContactUs";
import Whychoose from "./pages/Whychoose";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Career";
import Payment from "./pages/Payment";
import CareersDetails from "./pages/CareerDetails";
import Track from "./pages/Track";
import Faq from "./pages/Faq";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "flowbite";
import "./App.css";
import PaymentSuccess from "./pages/PayamenSuccess";
import PaymentNotSuccess from "./pages/PaymentNotSuccess";
import RequestingStationery from "./pages/requesting-stationery";
import DutiesandTaxes from "./pages/DutiesandTaxesFaq";
import DeliveryStatus from "./pages/DeliveryStatusFaq";
import ImportsFaq from "./pages/ImportsFaq";
import CustomscodesFaq from "./pages/CustomscodesFaq";
import PaymentsFaq from "./pages/PaymentsFaq";
import CustomsFaq from "./pages/CustomsFaq";
import ArrangingapickupFaq from "./pages/ArrangingapickupFaq";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DashboardOld from "./pages/Dashboard";
import Dashboard from "./pages/CustomerPortal/Dashboard";
import OpenInvoice from "./pages/CustomerPortal/OpenInvoice";
import PastDueInvoice from "./pages/CustomerPortal/PastDueInvoice";

import InvoiceDetailView from "./pages/CustomerPortal/InvoiceDetailView";
import PaidClosedInvoice from "./pages/CustomerPortal/PaidClosedInvoice";
import InDisputeInvoice from "./pages/CustomerPortal/InDisputeInvoice";
import PaymentCart from "./pages/CustomerPortal/PaymentCart";
import PaymentMethod from "./pages/CustomerPortal/PaymentMethod";
import PaymentConfirm from "./pages/CustomerPortal/PaymentConfirm";
import PaymentGateway from "./pages/CustomerPortal/PaymentGateway";
import SecurityPrivacy from "./pages/SecurityPrivacy";
import InvoicePaymentFail from "./pages/CustomerPortal/InvoicePaymentFail";
import InvoicePaymentSuccess from "./pages/CustomerPortal/InvoicePaymentSuccess";
function App() {
  const [isLoading, setLoading] = useState(true);

  function someRequest() {
    //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".preloader");
      console.log(loaderElement);
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/login" element={<Login />} />
          <Route path="/shipping-rates" element={<ShippingRates />} />
          <Route path="/schedule-pickup" element={<SchedulePickup />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/Whychoose-us" element={<Whychoose />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/career-details" element={<CareersDetails />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/track" element={<Track />} />
          <Route path="/payments" element={<Payment />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-fail" element={<PaymentNotSuccess />} />
          <Route path="/order-details" element={<RequestingStationery />} />
          <Route path="/faq-duties-and-taxes" element={<DutiesandTaxes />} />
          <Route path="/faq-delivery-status" element={<DeliveryStatus />} />
          <Route path="/faq-imports" element={<ImportsFaq />} />
          <Route path="/faq-customscodes" element={<CustomscodesFaq />} />
          <Route path="/faq-customscodes" element={<CustomscodesFaq />} />
          <Route path="/faq-payments" element={<PaymentsFaq />} />
          <Route path="/faq-customs" element={<CustomsFaq />} />
          <Route
            path="/faq-arrangingapickup"
            element={<ArrangingapickupFaq />}
          />
          <Route path="/security-privacy" element={<SecurityPrivacy />} />
          {/* Customer Billing Portal route start */}
          <Route path="/dashboard-old" element={<DashboardOld />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/open-invoice" element={<OpenInvoice />} />
          <Route path="/invoice-details-view" element={<InvoiceDetailView />} />
          <Route path="/past-due-invoices" element={<PastDueInvoice />} />
          <Route path="/Paid-closed-invoices" element={<PaidClosedInvoice />} />
          <Route path="/in-dispute-invoices" element={<InDisputeInvoice />} />
          <Route path="/payment-cart" element={<PaymentCart />} />
          <Route path="/payment-method" element={<PaymentMethod />} />
          <Route path="/payment-confirm" element={<PaymentConfirm />} />
          <Route path="/payment-getway" element={<PaymentGateway />} />
          <Route
            path="/invoice-payment-fail"
            element={<InvoicePaymentFail />}
          />
          <Route
            path="/invoice-payment-success"
            element={<InvoicePaymentSuccess />}
          />

          {/* Customer Billing Portal route end*/}
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
