import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const ArrangingapickupFaq = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
            <section className="banner-wrapper" style={{backgroundImage: `url(${"assets/img/faq-banner.png"})`,}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-title">
                            <h1>Frequently asked questions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-wrap faq2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><a href="/faq">FAQ</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Arranging a pickup</li>
                            </ol>
                        </nav>
                        <h2 className="faqtitle">Common questions asked by customers</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    {/* <!-- mobile search --> */}
                    <div className="faq-search-mobile">
                        <div className="row">
                            <div className="col-12">
                                <div className="search-form">
                                    <input type="text" className="form-control" placeholder="Search your question"></input>
                                    <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- mobile search end --> */}
                    <div className="faq-sidebar">                     
                        <div className="faqwrapper">
                            <div className="faq-link-wrap" data-aos="fade-down">
                            <h2>Arranging a pickup</h2>
                            <ul>
                                <li> <a href="#f1">How do I find a FedEx location near me?</a></li>
                                <li> <a href="#f1">How do I schedule a pickup?</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="faq-content-col">
                        <div className="faq-search-wrap">
                        <div className="faq-search-dk">
                            <div className="row">
                                    <div className="col-12">
                                        <div className="search-form">
                                        <input type="text" className="form-control" placeholder="Search your question"></input>
                                        <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img></button>
                                        </div>
                                    </div>
                            </div>
                        </div>                     
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-contents faqlist" data-aos="fade-down">
                                        <h2>Arranging a pickup</h2>  
                                        <div className="quick-faq">
                                        <ul>
                                            <li><a href=""style={{color:'#333',fontSize: 28}}>How do I find a FedEx location near me?</a></li>
                                            <p>Visit our location page to find a FedEx location near you.</p>
                                            <br></br>
                                            <li><a href=""style={{color:'#333',fontSize: 28}}>How do I schedule a pickup?</a></li>
                                            <p>To schedule a pickup online, you need to have a fedex.com User ID.</p>
                                            <br></br>
                                            <li><a href=""style={{color:'#333',fontSize: 28}}>How do I get proof of the delivery from FedEx?</a></li>
                                            <p>Go to FedEx Tracking, enter your 12-digit tracking number and click "Track".
                                                <br></br>
                                            Click “OBTAIN PROOF OF DELIVERY”, enter the 9-digit shipper or payer FedEx account number associated with the shipment, then “view PDF”.
                                            <br></br>
                                            Get in touch with our Customer Service for further enquiries about our services.
                                            <br></br>
                                            <br></br>
                                            CONTACT US
                                            <br></br>
                                            Email: info@fedexlk.com
                                            <br></br>
                                            Hotline: + 94 114 522 222

                                                </p>
                                            <br></br>
                                        </ul>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        
                                        </div>                                 
                                    </div>                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
    );
  }
  
  export default ArrangingapickupFaq;