import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const Faq = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
         <section className="banner-wrapper" style={{backgroundImage: `url(${"assets/img/faq-banner.png"})`,}}>
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="banner-title">
                           <h1>Frequently Asked Questions</h1>
                        </div>
                     </div>
                  </div>
               </div>
         </section>
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="breadcrumb-wrap faq2">
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item"><a href="#">Home</a></li>
                           <li className="breadcrumb-item active" aria-current="page">FAQ</li>
                        </ol>
                     </nav>
                     <h2 className="faqtitle">Customer FAQs: A Quick Reference Guide</h2>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-4">
                  {/* <!-- mobile search --> */}
                  <div className="faq-search-mobile">
                     <div className="row">
                           <div className="col-12">
                              <div className="search-form">
                                 <input type="text" className="form-control" placeholder="Search your question"></input>
                                 <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img> </button>
                              </div>
                           </div>
                     </div>
                  </div>
                  {/* <!-- mobile search end --> */}
                  <div className="faq-sidebar">                     
                     <div className="faqwrapper">
                        <div className="faq-link-wrap" data-aos="fade-down">
                           <h2>Duties and Taxes</h2>
                           <ul>
                              <li> <a href="#f1">Delivery Status</a></li>
                              <li> <a href="#f2">Delivery Management</a></li>
                              <li> <a href="#f3">Paying an Invoice</a></li>
                              <li> <a href="#f4">Duties and Taxes</a></li>
                              <li> <a href="#f5">DoorTags/Missed Delivery</a></li>
                           </ul>
                        </div>
                        <hr/>
                        <div className="faq-link-wrap mt-4" data-aos="fade-down">
                           <h2>Documents</h2>
                           <ul>
                              <li> <a href="">Delivery Status</a></li>
                              <li> <a href="">Delivery Management</a></li>
                              <li> <a href="">DoorTags/Missed Delivery</a></li>
                           </ul>
                        </div>
                        <div className="faq-link-wrap mt-4" data-aos="fade-down">
                           <h2>Imports</h2>
                           <ul>
                              <li> <a href="">Delivery Status</a></li>
                           </ul>
                        </div>
                        <div className="faq-link-wrap mt-4" data-aos="fade-down">
                           <h2>Customs codes</h2>
                           <ul>
                              <li> <a href="">Delivery Status</a></li>
                           </ul>
                        </div>
                        <div className="faq-link-wrap mt-4" data-aos="fade-down">
                           <h2>Payments</h2>
                           <ul>
                              <li> <a href="">Delivery Status</a></li>
                              <li> <a href="">Delivery Management</a></li>
                              <li> <a href="">DoorTags/Missed Delivery</a></li>
                              <li> <a href="">Duties and Taxes</a></li>
                              <li> <a href="">Paying an Invoice</a></li>
                           </ul>
                        </div>
                        <div className="faq-link-wrap mt-4" data-aos="fade-down">
                           <h2>Customs</h2>
                           <ul>
                              <li> <a href="">Delivery Status</a></li>
                              <li> <a href="">Delivery Management</a></li>
                              <li> <a href="">DoorTags/Missed Delivery</a></li>
                              <li> <a href="">Duties and Taxes</a></li>
                              <li> <a href="">Paying an Invoice</a></li>
                           </ul>
                        </div>
                        <div className="faq-link-wrap mt-4" data-aos="fade-down">
                           <h2>Arranging a pickup</h2>
                           <ul>
                              <li> <a href="">Delivery Status</a></li>
                              <li> <a href="">Delivery Management</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-8">
                  <div className="faq-content-col">
                    <div className="faq-search-wrap">
                       <div className="faq-search-dk">
                           <div className="row">
                                 <div className="col-12">
                                    <div className="search-form">
                                       <input type="text" className="form-control" placeholder="Search your question"></input>
                                       <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img></button>
                                    </div>
                                 </div>
                           </div>
                       </div>                     
                        <div className="row">
                            <div className="col-md-12">
                                <div className="search-contents faqlist" data-aos="fade-down">
                                    <h2>Duties and Taxes</h2>  
                                    <div className="quick-faq">
                                       <ul>
                                          <li><a href="faq-duties-and-taxes">How are the Duty and Tax charges calculated?</a></li>
                                          <li><a href="faq-duties-and-taxes">The package was a gift, why have I been charged? </a></li>
                                          <li><a href="faq-duties-and-taxes">I purchased these goods on the Internet, why have I been charged?</a> </li>
                                          <li><a href="faq-duties-and-taxes">The goods were second hand, why have I been charged? </a></li>
                                          <li><a href="faq-duties-and-taxes">I paid tax when I bought this item, why do I have to pay more?</a></li>
                                          <li><a href="faq-duties-and-taxes">I believe the shipper paid for all charges relating to this shipment?</a></li>
                                          <li><a href="faq-duties-and-taxes">I pre-paid duty/tax charges before my goods were delivered, do I have to pay more?</a></li>
                                          <li><a href="faq-duties-and-taxes">I am a temporary visitor to Sri Lanka, do I still have to pay import charges?</a></li>
                                          <li><a href="faq-duties-and-taxes">Do I have to pay for samples?</a></li>
                                          <li><a href="faq-duties-and-taxes">I want to reject this shipment how do I do this?</a></li>
                                       </ul>
                                      
                                    </div>                                 
                                </div>
                                 {/* <!-- faq block 1 --> */}
                                <div className="faq-block">
                                    <div className="search-contents faqlist" >
                                          <h2>Documents</h2>  
                                          <div className="faq-subblock" id="f1" data-aos="fade-down">
                                                <div className="quick-faq">
                                                   <ul>
                                                      <li><a href="/faq-delivery-status">What is a Commercial Invoice?</a></li>
                                                      <li><a href="/faq-delivery-status">What is a Packing List? </a></li>
                                                      <li><a href="/faq-delivery-status">What is a Certificate of Origin? </a> </li>   
                                                   </ul>                                          
                                                </div>
                                          </div>                                                                   
                                    </div>
                                </div>
                                <div className="faq-block">
                                    <div className="search-contents faqlist" >
                                          <h2>Imports</h2>  
                                          <div className="faq-subblock" id="f1" data-aos="fade-down">
                                                <div className="quick-faq">
                                                   <ul>
                                                      <li><a href="/faq-delivery-status">I tracked my shipment, and it appears there is a customs or clearance delay. What should I do?</a></li>
                                                   </ul>                                          
                                                </div>
                                          </div>                                                                   
                                    </div>
                                </div>
                                <div className="faq-block">
                                    <div className="search-contents faqlist" >
                                          <h2>Customs codes</h2>  
                                          <div className="faq-subblock" id="f1" data-aos="fade-down">
                                                <div className="quick-faq">
                                                   <ul>
                                                      <li><a href="/faq-customscodes">What is a harmonized or HS code?</a></li>
                                                   </ul>                                          
                                                </div>
                                          </div>                                                                   
                                    </div>
                                </div>
                                <div className="faq-block">
                                 <div className="search-contents faqlist" data-aos="fade-down">
                                       <h2>Payments</h2>  
                                       <div className="quick-faq">
                                          <ul>
                                             <li><a href="/faq-payments">How do I calculate duties?</a></li>
                                             <li><a href="/faq-payments">How do I calculate estimated Duties and Taxes? </a></li>
                                          </ul>
                                       
                                       </div>                                 
                                 </div>
                                </div>
                                <div className="faq-block">
                                 <div className="search-contents faqlist" data-aos="fade-down">
                                       <h2>Customs</h2>  
                                       <div className="quick-faq">
                                          <ul>
                                             <li><a href="/faq-customs">Does FedEx provide customs clearance services?</a></li>
                                             <li><a href="/faq-customs">Why would a package be held in customs? </a></li>
                                          </ul>
                                       
                                       </div>                                 
                                 </div>
                                </div>
                                <div className="faq-block">
                                <div className="search-contents faqlist" data-aos="fade-down">
                                    <h2>Arranging a pickup</h2>  
                                    <div className="quick-faq">
                                       <ul>
                                          <li><a href="/faq-arrangingapickup">How do I find a FedEx location near me?</a></li>
                                          <li><a href="/faq-arrangingapickup">How do I schedule a pickup? </a></li>
                                          <li><a href="/faq-arrangingapickup">How do I get proof of the delivery from FedEx?</a> </li>
                                       </ul>
                                    </div>                                 
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <section className="package-lab">
             <div className="container">
                <div className="row">
                   <div className="col-md-12">
                      <h2>Can't find an answer to a question?</h2>
                   </div>
                </div>             
                 <div className="row g-1">
                     <div className="col-lg-6 col-md-12">
                         <div className="p-lab-image">
                             <img src="assets/img/faq.jpg" alt="" className="img-fluid"></img>
                         </div>
                     </div>
                     <div className="col-lg-6 col-md-12">
                        <div className="p-lab-text">
                            <h4>Visit Customer Support</h4>
                            <p>Don’t see your question? <br>
                            </br>Didn't find the answers you need?</p>
                             <a href="/customer-support"  className="learnmore">Visit Customer Support</a>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
          <Footer/>
    </React.Fragment>
    );
  }
  
  export default Faq;