const API_URL = 'https://www.system.mountain-hawk.online/fedex/public/api'

//const API_URL = 'http://localhost:90/api'
export const API_ROUTES = {
  SIGN_UP: `${API_URL}/auth/signup`,
  SIGN_IN: `${API_URL}/rate/login`,
  SIGN_OUT: `${API_URL}/rate/logout`,
  REGISTER: `${API_URL}/rate/registration`,
  VERIFY: `${API_URL}/rate/confirm_auth`,
  VALIDATE: `${API_URL}/rate/validate`,
  GET_USER: `${API_URL}/rate/send_auth`,
  SEND_AUTH: `${API_URL}/rate/send_auth`,
  FORGET_PASSWORD: `${API_URL}/rate/forget_password`,
  GET_COUNTRY: `${API_URL}/settings/country/get`,
  GET_OUT_RATE: `${API_URL}/rate/getOutRate`,
  GET_IN_RATE: `${API_URL}/rate/getInRate`,
  TRACK: `${API_URL}/track/track?id=`,
  PAYMENT: `${API_URL}/payment/save`,
  HNBGATEWAY: `${API_URL}/payment/hnb_payment`,
  ContactUs: `${API_URL}/conatct/conatct_us`,
  ACCOUNT_SUMMERY: `${API_URL}/customer-portal/auth/account-summary`,
  PRIMARY_ACCOUNT: `${API_URL}/customer-portal/auth/account-aging-summary`,
  INVOICE_LIST: `${API_URL}/customer-portal/auth/invoice-list`,
  INVOICE_DETAILS: `${API_URL}/customer-portal/auth/invoice-details`,
  PORTAL : `${API_URL}/rate/check_portal`,
};

export const APP_ROUTES = {
  SIGN_UP: '/signup',
  SIGN_IN: '/rate/login',
  SIGN_OUT: '/rate/logout',
  DASHBOARD: '/dashboard',
}

 