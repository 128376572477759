import React, { useState, useEffect } from "react";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/CustomerPortal/SideBar";
import { NumericFormat } from "react-number-format";
// import { getTokenFromLocalStorage } from "../../lib/common";
// import { API_ROUTES } from "../../utils/constants";
// import { useLocation } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";

const PaymentCart = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [error, setError] = useState(null);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("");
  const invoiceNumbers = localStorage.getItem("openInvoice");

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("openInvoice"));
    if (items) {
      setInvoiceData(items);
    }
  }, []);

  useEffect(() => {
    let sum = 0;
    invoiceData.forEach((item) => {
      sum += parseFloat(item["Original Charges LKR"]); // Assuming charges are numeric
    });
    setTotalInvoiceAmount(sum);
  }, [invoiceData]);

  const handlePaymentAmountChange = (e, index) => {
    invoiceData[index]["Original Charges LKR"] = e.target.value;

    let sum = 0;
    invoiceData.forEach((item) => {
      sum += parseFloat(item["Original Charges LKR"]); // Assuming charges are numeric
    });
    localStorage.setItem("openInvoice", JSON.stringify(invoiceData));
    setTotalInvoiceAmount(sum);
  };

  const isPaymentAmountValid = () => {
    return parseFloat(totalInvoiceAmount) > 0;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-UK", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  if (error) {
    return <div>{error}</div>;
  }

  if (!invoiceData) {
    return <div>Loading...</div>;
  }
  const billingInfo = invoiceData.BillingInformation || {};
  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div class="p-4 sm:ml-64">
        <div class="p-4 rounded-lg  ">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div class="text-xs text-gray-700  dark:text-gray-400">
              <ol class="flex items-center w-full p-3 justify-center space-x-8 text-sm font-medium text-center gap-20 text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                <li class="flex items-center text-blue-600 dark:text-blue-500">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                    1
                  </span>
                  Select Payment
                  <span class="hidden sm:inline-flex sm:ms-2"></span>
                  <svg
                    class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li class="flex items-center">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    2
                  </span>
                  Payment Method
                  <span class="hidden sm:inline-flex sm:ms-2"></span>
                  <svg
                    class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li class="flex items-center">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    3
                  </span>
                  Payment Confirmation
                </li>
              </ol>
            </div>
          </div>
          <div class="">
            <h2 className="text-2xl pt-4 pl-4 md:pl-12 md:pr-8 flex justify-center pb-2">
              MY PAYMENT CART
            </h2>
          </div>
          <div class="grid justify-center pb-4">
            <p className="text-gray-500 mt-1">
              Amount To Pay :
              <NumericFormat
                value={totalInvoiceAmount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={""}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
          </div>
          <div className="">
            <p className="text-gray-500 mt-4 font-bold pb-10">
              View Items In Payment Cart
            </p>
          </div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg pt-3">
            <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <tr>
                  <th className="border-b px-2 py-2 font-normal">Account No</th>
                  <th className="border-b px-2 py-2 font-normal">Invoice No</th>
                  <th className="border-b px-2 py-2 font-normal">
                    Invoice Date
                  </th>
                  <th className="border-b px-2 py-2 font-normal">Due Date</th>
                  <th className="border-b px-2 py-2 font-normal">Currency</th>
                  <th className="border-b px-2 py-2 font-normal">Amount Due</th>
                  <th className="border-b px-2 py-2 font-normal">
                    Payment Amount
                  </th>
                  <th className="border-b px-2 py-2 font-normal">Action</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData.map((invoice, index) => (
                  <tr key={invoice?.BillingInformation?.["Invoice No"]}>
                    <td className="px-2 py-2">{invoice?.["Account No"]}</td>
                    <td className="px-2 py-2">{invoice?.["Invoice No"]}</td>
                    <td className="px-2 py-2">
                      {formatDate(invoice?.["Invoice Date"])}
                    </td>
                    <td className="px-2 py-2">
                      {formatDate(invoice?.["Due Date"])}
                    </td>
                    <td className="px-2 py-2"> LKR</td>
                    <td className="px-2 py-2 text-right pr-3">
                      <NumericFormat
                        value={invoice?.["Original Charges LKR"]}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className="pr-3"
                      />
                    </td>
                    <td className="px-2 py-2 flex justify-center">
                      <NumericFormat
                        value={invoice?.["Original Charges LKR"]}
                        thousandSeparator={true}
                        prefix={""}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={(values) => {
                          const { value } = values;
                          handlePaymentAmountChange(
                            { target: { value } },
                            index
                          );
                        }}
                        className="bg-gray-50 border text-right border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </td>
                    <td className="px-2 py-2">
                      <button type="button">
                        <RiDeleteBin5Line className="text-red-500 size-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pt-10 flex justify-between">
            <a href="/open-invoice">
              <button
                type="button"
                class="text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-1 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
              >
                <div className="flex flex-nowrap ">
                  <div className="flex items-center pr-2">
                    <svg
                      class="w-3 h-3 ms-2 sm:ms-4 transform rotate-180 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  </div>
                  BACK
                </div>
              </button>
            </a>

            <a href="/payment-method">
              {!isPaymentAmountValid() && <div></div>}
              <button
                disabled={!isPaymentAmountValid()}
                type="button"
                className="text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-1 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
              >
                <div className="flex flex-nowrap ">
                  NEXT
                  <div className="flex items-center">
                    <svg
                      class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentCart;
