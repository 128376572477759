import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const SchedulePickup = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Welcome to React' , group:'shipping' }}   />
          <div className="contentswrap">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">SCHEDULE A PICK-UP</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="col-md-12">
                <div className="form-wrap-titles">
                    <h1>Schedule a pick-up</h1>
                </div>
                <div className="form-wrap pickups">
                    <div className="row">
                        <div className="form-floating mb-3 col-md-12" >
                            <input type="text" className="form-control" id="" placeholder="Pickup"/>
                            <label for="floatingInput">Company Name</label>
                        </div>                       
                    </div>
                    <div className="row">
                        <div className="form-floating mb-3 col-md-12" >
                            <input type="text" className="form-control" id="" placeholder="Pickup"/>
                            <label for="floatingInput">Address*</label>
                        </div>                       
                    </div>
                    <div className="row">
                        <div className="form-floating mb-3 col-md-12" >
                            <input type="text" className="form-control" id="" placeholder="Pickup"/>
                            <label for="floatingInput">City</label>
                        </div>                       
                    </div>

                    <div className="contacts-info">
                        <h3>Contact details</h3>
                        <div className="row">
                            <div className="form-floating mb-3 col-md-12" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Contact Person*</label>
                            </div>                       
                        </div>
                        <div className="row">
                            <div className="form-floating mb-3 col-md-6" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Contact Number*</label>
                            </div>  
                            <div className="form-floating mb-3 col-md-6" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Secondary Contact Number</label>
                            </div>                     
                        </div>
                        <div className="row">
                            <div className="form-floating mb-3 col-md-12" >
                                <textarea name="" id="wcount"  maxlength="140"  className="form-control"   placeholder="Pickup"></textarea>
                                <label for="floatingInput">Remarks</label>
                                <div className="counterwrap">
                                     <p>word count</p><span className="countlabel" id="count_message"></span>
                                </div>                                
                            </div>                       
                        </div>
                    </div>
                    <div className="contacts-info pkage">
                        <h3>Package details</h3>                        
                        <div className="row g-0">
                            <div className="form-floating mb-3 col-md-6" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Package ready time*</label>
                            </div>  
                            <div className="form-floating mb-3 col-md-6" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Closing Time</label>
                            </div>                     
                        </div>
                        <div className="row g-0">
                            <div className="form-floating mb-3 col-md-6" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Number of Packages*</label>
                            </div>  
                            <div className="form-floating mb-3 col-md-6" >
                                <input type="text" className="form-control" id="" placeholder="Pickup"/>
                                <label for="floatingInput">Total package weight*</label>
                                <span className="pweight">Kg</span>
                            </div>                     
                        </div>
                        <div className="row">
                            <div className="form-floating mb-3 col-md-12" >
                                <select className="form-select payor" id="floatingSelect" aria-label="Floating label select example">
                                        <option selected>Select Payor Party *</option>   
                                        <option >Monday, January 20, 2023</option> 
                                </select>                                
                            </div>                                               
                        </div> 
                        <div className="row g-0">
                            <div className="form-floating mb-3 col-md-12 acc">
                              <input type="text" className="form-control" id="floatingInputInvalid" placeholder="name@example.com" value="93204093240" disabled/>
                              <label for="floatingInputInvalid" disabled>FedEx Account Number*</label>  
                            </div>                                          
                        </div>   
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn_register">SCHEDULE PICK-UP</button>  
                        </div>
                    </div>
                </div>  
                {/* <div className="assistance">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="assistance-title">
                                    <h4>Need any assistance ?</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-6">
                                <a href="how-to-pack.php" className="assistance-block">
                                    <img src="assets/img/a1.png" alt=""/>   
                                    <h5>Learn to pack</h5>                                 
                                 </a>
                            </div>
                            <div className="col-md-3 col-6">
                                <a href="shipping-rates.php" className="assistance-block">
                                    <img src="assets/img/a2.png" alt=""/>   
                                    <h5>Start a shipment</h5>                                 
                                 </a>
                            </div>
                            <div className="col-md-3 col-6">
                                <a href="login.php" className="assistance-block">
                                    <img src="assets/img/a3.png" alt=""/>   
                                    <h5>Manage my account</h5>                                 
                                 </a>
                            </div>
                            <div className="col-md-3 col-6">
                                <a href="contact-us.php" className="assistance-block">
                                    <img src="assets/img/a4.png" alt=""/>   
                                    <h5>Find FedEx locations</h5>                                 
                                 </a>
                            </div>
                        </div>
                    </div>
                </div>            */}
            </div>
        </div>
    </div>
   
</div>
          <Footer/>
    </React.Fragment>
    );
  }
  
  export default SchedulePickup;