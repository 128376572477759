import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const CustomsFaq = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
            <section className="banner-wrapper" style={{backgroundImage: `url(${"assets/img/faq-banner.png"})`,}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-title">
                            <h1>Frequently asked questions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-wrap faq2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><a href="/faq">FAQ</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Customs</li>
                            </ol>
                        </nav>
                        <h2 className="faqtitle">Common questions asked by customers</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    {/* <!-- mobile search --> */}
                    <div className="faq-search-mobile">
                        <div className="row">
                            <div className="col-12">
                                <div className="search-form">
                                    <input type="text" className="form-control" placeholder="Search your question"></input>
                                    <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- mobile search end --> */}
                    <div className="faq-sidebar">                     
                        <div className="faqwrapper">
                            <div className="faq-link-wrap" data-aos="fade-down">
                            <h2>Customs</h2>
                            <ul>
                                <li> <a href="#f1">I tracked my shipment, and it appears there is a customs or clearance delay. What should I do?</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="faq-content-col">
                        <div className="faq-search-wrap">
                        <div className="faq-search-dk">
                            <div className="row">
                                    <div className="col-12">
                                        <div className="search-form">
                                        <input type="text" className="form-control" placeholder="Search your question"></input>
                                        <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img></button>
                                        </div>
                                    </div>
                            </div>
                        </div>                     
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-contents faqlist" data-aos="fade-down">
                                        <h2>Customs</h2>  
                                        <div className="quick-faq">
                                        <ul>
                                            <li><a href=""style={{color:'#333',fontSize: 28}}>Does FedEx provide customs clearance services?</a></li>
                                            <p>Yes, FedEx acts as an intermediary between the customer and the customs authorities throughout the customs clearance process, unless instructed otherwise, and provides support when things get complicated. For example, if your shipment has specific requirements for clearance, we can help you find the right solution.
                                            <br></br>
                                            Our customs clearance services vary by country/territory depending on local permits, regulations and processing.
                                            </p>
                                            <br></br>
                                            <li><a href=""style={{color:'#333',fontSize: 28}}>Why would a package be held in customs?</a></li>
                                            <p>Shipments are held in customs to ensure they satisfy export or import customs regulations. A held shipment may have missing or incomplete paperwork, be waiting for clearance instructions or the authorities may need more information to clear the goods. Until we can provide all the information to the authorities, we cannot process the shipment for clearance. If you have chosen to use your own customs broker, then your broker will handle this for you
                                            <br></br>
                                            If your package is held at customs, FedEx will contact you for more information.
                                            </p>
                                            <br></br>
                                        </ul>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        
                                        </div>                                 
                                    </div>                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
    );
  }
  
  export default CustomsFaq;