import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/CustomerPortal/SideBar";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_ROUTES } from "../../utils/constants";
import { getTokenFromLocalStorage } from "../../lib/common";
import { Bars } from "react-loader-spinner";
import { format } from "date-fns";
import { EnvelopeOpenIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import { NumericFormat } from "react-number-format";

const PaidClosedInvoice = () => {
  const [list, setList] = useState([]);
  const currentDate = moment();
  const [dataLoad, setDataLoad] = useState(false);
  const [endDate, setEndDate] = useState(currentDate.format("YYYY/MM/DD"));
  const [startDate, setStartDate] = useState(
    currentDate.subtract(45, "days").format("YYYY/MM/DD")
  );
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#223336",
        backgroundColor: "#e7eef0",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        textAlign: "center",
      },
      stripedStyle: {
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toDateChange = (date) => {
    setDataLoad(false);
    fetchData();
  };

  const fetchData = async () => {
    try {
      const config = {
        Authorization: getTokenFromLocalStorage(),
      };

      let value = {
        FromDate: moment(startDate).format("YYYY-MM-DD"),
        ToDate: moment(endDate).format("YYYY-MM-DD"),
        IsPaid: "Y",
      };
      const response = await axios({
        method: "post",
        url: API_ROUTES.INVOICE_LIST,
        data: value,
        headers: config,
      });

      setList(response.data?.data || []);
      setDataLoad(response.data.meta?.status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const numberFormat = (number) => {
    return (
      <NumericFormat
        value={number}
        displayType={"text"}
        thousandSeparator={true}
        prefix={""}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  };

  const columns = [
    {
      name: "Invoice no",
      selector: (row) => row["Invoice No"],
    },
    {
      name: "Invoice Date",
      selector: (row) => format(new Date(row["Invoice Date"]), "yyyy.MMM.dd "),
    },
    {
      name: "Due Date",
      selector: (row) => format(new Date(row["Due Date"]), "yyyy.MMM.dd "),
    },
    {
      name: "Account No",
      selector: (row) => row["Account No"],
    },
    {
      name: "Original Charges",
      selector: (row) => numberFormat(row["Original Charges LKR"]),
      cell: (row) => (
        <div style={{ textAlign: "right" }}>
          {numberFormat(row["Original Charges LKR"])}
        </div>
      ),
      right: true,
      style: {
        justifyContent: "flex-end",
      },
    },
    // {
    //   name: "Actions",
    //   cell: () => (
    //     <div className="flex space-x-1">
    //       <button>
    //         <EnvelopeOpenIcon className="h-4 w-4 text-blue-400" />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div class="p-4 sm:ml-64">
        <h3 className="text-4xl text-blue pt-14  md:pl-20 md:pr-20 font-bold flex justify-center">
          PAID INVOICES
        </h3>
        <div className="mb-8 pr-3  "></div>
        <div class="p-4 rounded-lg  ">
          <div className="mb-8 pr-3 flex flex-wrap items-center">
            <div className="flex items-center">
              <div className="flex items-center">
                <label className="mr-2">From :</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="yyyy/MMM/dd"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
            </div>
            <div className="flex items-center ml-10 mr-10">
              <label className="mr-2 pl-2">To :</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy/MMM/dd"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <button onClick={toDateChange} className="bg-indigo-600 p-2">
              <FaSearch className="text-slate-300 size-5" />
            </button>
          </div>
          <div class="p-4 rounded-lg  ">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <div class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <div class="flex flex-row  justify-center items-center">
                  <Bars
                    height="80"
                    width="80"
                    color="#4d148c"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={!dataLoad}
                  />{" "}
                </div>
                <DataTable
                  visible={list.length != 0}
                  columns={columns}
                  data={list}
                  customStyles={tableCustomStyles}
                />
              </div>
            </div>
            {/* <div className="flex space-x-1 py-8 pr-5 justify-end">
            <button className="bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 text-white font- py-1 px-1 shadow-md sm:rounded-lg">
              Download / Print Invoices
            </button>

            <button className="bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 text-white font- py-1 px-1 shadow-md sm:rounded-lg">
              Pay Invoices
            </button>
          </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PaidClosedInvoice;
