import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/CustomerPortal/SideBar";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_ROUTES } from "../../utils/constants";
import { getTokenFromLocalStorage } from "../../lib/common";
import { Bars } from "react-loader-spinner";
import { format } from "date-fns";
import {
  EnvelopeOpenIcon,
} from "@heroicons/react/24/solid";

const InDisputeInvoice = () => {
  const [list, setList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#223336",
        backgroundColor: "#e7eef0",
      },
    },
    rows: {
      style: {
        color: "STRIPEDCOLOR",
        backgroundColor: "STRIPEDCOLOR",
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR",
      },
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          Authorization: getTokenFromLocalStorage(),
        },
      };
      const response = await axios.get(API_ROUTES.INVOICE_LIST, config);
      setList(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = [
    {
      name: "Invoice no",
      selector: (row) => row["Invoice No"],
    },
    {
      name: "Invoice Date",
      selector: (row) => format(new Date(row["Invoice Date"]), "yyyy.MM.dd "),
    },
    {
      name: "Due Date",
      selector: (row) => format(new Date(row["Due Date"]), "yyyy.MM.dd "),
    },
    {
      name: "Account No",
      selector: (row) => row["Account No"],
    },
    {
      name: "Original Charges",
      selector: (row) => row["Original Charges USD"],
    },
    {
      name: "Dispute Date",
      selector: (row) => row[""],
    },
    {
      name: "Balance Due",
      selector: (row) => row["Balance Due LKR"],
    },
    {
      name: "Dispute Reason",
      selector: (row) => row[""],
    },
    {
      name: "Actions",
      cell: () => (
        <div className="flex space-x-1">
          <button>
            <EnvelopeOpenIcon className="h-4 w-4 text-blue-400" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div class="p-4 sm:ml-64">
        <h2 className="text-2xl pt-4 pl-4 md:pl-12 md:pr-8 pb-6">
          IN DISPUTE INVOICE LIST
        </h2>
        <div class="p-4 rounded-lg  ">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div class="text-xs text-gray-700 uppercase dark:text-gray-400 ">
              <div class="flex flex-row  justify-center items-center">
                <Bars
                  height="80"
                  width="80"
                  color="#4d148c"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={list.length == 0}
                />{" "}
              </div>
              <DataTable
                visible={list.length != 0}
                columns={columns}
                data={list}
                customStyles={tableCustomStyles}
              />
            </div>
          </div>
          <div className="flex space-x-1 py-8 pr-5 justify-end">
            <button className="bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 text-white font- py-1 px-1 shadow-md sm:rounded-lg">
              Download / Print Invoices
            </button>

            <button className="bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 text-white font- py-1 px-1 shadow-md sm:rounded-lg">
              Pay Invoices
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default InDisputeInvoice;
