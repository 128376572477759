import React, { useState, useEffect } from "react";
import Sidebar from "../../components/CustomerPortal/SideBar";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
import { API_ROUTES } from "../../utils/constants";
import { getFirstLastNameFromLocalStorage } from "../../lib/common";
import { toast } from "react-toastify";
import axios from "axios";
import { NumericFormat } from "react-number-format";

const PaymentConfirm = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [error, setError] = useState(null);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [stege, setStage] = useState(1);
  const [selectedInvoiceNumbers, setSelectedInvoiceNumbers] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("openInvoice"));
    const invoiceNumbers = JSON.parse(
      localStorage.getItem("selectedInvoiceNumbers")
    );
    if (items) {
      setInvoiceData(items);
      setSelectedInvoiceNumbers(invoiceNumbers || []);
    }
  }, []);

  useEffect(() => {
    let sum = 0;
    invoiceData.forEach((item) => {
      sum += parseFloat(item["Original Charges LKR"]); // Assuming charges are numeric
    });
    setTotalInvoiceAmount(sum);
  }, [invoiceData]);

  useEffect(() => {
    if (totalInvoiceAmount !== 0) {
      console.log(totalInvoiceAmount);
      fetchData();
    }
  }, [totalInvoiceAmount]);

  async function fetchData() {
    let value = {
      amount: totalInvoiceAmount,
      ref_number: Date.now(),
      name_cus: getFirstLastNameFromLocalStorage(),
      type: 2,
      company: "aa",
      address: "ww",
      postal_code: "22",
      city: "dd",
      phone: "123456789",
      email: "sd2@gmail.com",
      invoice_numbers: selectedInvoiceNumbers,
    };
    if (stege === 1) {
      setStage(2);
      try {
        let { data } = await axios({
          method: "post",
          url: API_ROUTES.PAYMENT,
          data: value,
        });

        if (!data?.meta?.status) {
          toast.error("Error");
        } else {
          //   toast.success("Success:");

          let invoice = { invoice_id: data.data.id };
          data = await axios({
            method: "post",
            url: API_ROUTES.HNBGATEWAY,
            data: invoice,
          });
          await setPaymentRequest(data.data.data);
        }
      } catch (err) {}
    }
  }

  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div class="p-4 sm:ml-64">
        <div class="p-4 rounded-lg  ">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div class="text-xs text-gray-700  dark:text-gray-400">
              <ol class="flex items-center w-full p-3 justify-center space-x-8 text-sm font-medium text-center gap-20 text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                <li class="flex items-center">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    1
                  </span>
                  Select Payment
                  <span class="hidden sm:inline-flex sm:ms-2">Info</span>
                  <svg
                    class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li class="flex items-center">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    2
                  </span>
                  Payment Method
                  <span class="hidden sm:inline-flex sm:ms-2"></span>
                  <svg
                    class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li class="flex items-center text-blue-600 dark:text-blue-500">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                    3
                  </span>
                  Payment Confirmation
                </li>
              </ol>
            </div>
          </div>

          <div class="flex justify-center">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
              <div class="text-xs text-gray-700 uppercase dark:text-gray-400">
                <div className="carrer-row flex flex-col md:flex-row p-10">
                  <div className="items-right rounded bg-gray-50 dark:bg-gray-800 mr-7">
                    <h6 className="text-2xl">confirm Your Payment</h6>
                    <div class="grid justify-center">
                      <p className="flex text-gray-500 mt-4">
                        Payment Amount:
                        <NumericFormat
                          value={totalInvoiceAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={""}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-10 flex justify-center">
            {totalInvoiceAmount !== "0" && paymentRequest && (
              <form
                id="paymentform"
                action="https://secureacceptance.cybersource.com/pay"
                method="post"
              >
                <input
                  type="hidden"
                  id="access_key"
                  name="access_key"
                  value={paymentRequest.access_key}
                />
                <input
                  type="hidden"
                  id="profile_id"
                  name="profile_id"
                  value={paymentRequest.profile_id}
                />
                <input
                  type="hidden"
                  id="transaction_uuid"
                  name="transaction_uuid"
                  value={paymentRequest.transaction_uuid}
                />
                <input
                  type="hidden"
                  id="signed_field_names"
                  name="signed_field_names"
                  value={paymentRequest.signed_field_names}
                />
                <input
                  type="hidden"
                  id="unsigned_field_names"
                  name="unsigned_field_names"
                  value={paymentRequest.unsigned_field_names}
                />
                <input
                  type="hidden"
                  id="signed_date_time"
                  name="signed_date_time"
                  value={paymentRequest.signed_date_time}
                />
                <input
                  type="hidden"
                  id="locale"
                  name="locale"
                  value={paymentRequest.locale}
                />
                <input
                  type="hidden"
                  id="transaction_type"
                  name="transaction_type"
                  value={paymentRequest.transaction_type}
                />
                <input
                  type="hidden"
                  id="reference_number"
                  name="reference_number"
                  value={paymentRequest.reference_number}
                />
                <input
                  type="hidden"
                  id="amount"
                  name="amount"
                  value={paymentRequest.amount}
                />
                <input
                  type="hidden"
                  id="currency"
                  name="currency"
                  value={paymentRequest.currency}
                />
                <input
                  type="hidden"
                  id="bill_to_forename"
                  name="bill_to_forename"
                  value={paymentRequest.bill_to_forename}
                />
                <input
                  type="hidden"
                  id="bill_to_surname"
                  name="bill_to_surname"
                  value={paymentRequest.bill_to_surname}
                />
                <input
                  type="hidden"
                  id="bill_to_email"
                  name="bill_to_email"
                  value={paymentRequest.bill_to_email}
                />
                <input
                  type="hidden"
                  id="bill_to_address_line1"
                  name="bill_to_address_line1"
                  value={paymentRequest.bill_to_address_line1}
                />
                <input
                  type="hidden"
                  id="bill_to_address_line2"
                  name="bill_to_address_line2"
                  value={paymentRequest.bill_to_address_line2}
                />
                <input
                  type="hidden"
                  id="bill_to_address_country"
                  name="bill_to_address_country"
                  value={paymentRequest.bill_to_address_country}
                />
                <input
                  type="hidden"
                  id="bill_to_address_city"
                  name="bill_to_address_city"
                  value={paymentRequest.bill_to_address_city}
                />
                <input
                  type="hidden"
                  id="signature"
                  name="signature"
                  value={paymentRequest.signature}
                />
                <div className="flex justify-center pt-5">
                  <button
                    type="submit"
                    class="text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-2.5 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
                  >
                    Confirm Payment
                  </button>
                </div>
              </form>
            )}

            {/* <button
              type="button"
              class="text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-2.5 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
            >
              CONFIRM

              
            </button> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentConfirm;
