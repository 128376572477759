import Header from "../components/Header";
import Footer from "../components/Footer";
import { useForm } from 'react-hook-form';
import React from 'react';
import { useState, useEffect } from 'react';
import { API_ROUTES } from '../utils/constants';
import axios from 'axios';
import { Bars } from "react-loader-spinner";
import { NumericFormat } from 'react-number-format';
import { getICPCFromLocalStorage, verifyAuth} from '../lib/common';

const ShippingRates = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [country, setCountry] = React.useState([])
    const [userID,setICPC] = useState(104040909);
    const [rete, setRate] = React.useState([]);
    const [step, setStep] = useState("stage1");
    const [serviceDefault, setServiceDefault] = useState("");
    const [loader, setLoader] = useState(false);
    const [packageDefault, setPackageDefault] = useState("");
    const [countryDefault, setCountryDefault] = useState("");
    const [service, setService] = React.useState([
        { id: '1', name: "Outbound" },
        { id: '2', name: "Inbound" }
    ]);
    const [packange, setPackage] = React.useState([{ "id": '01', "name": "Other" }, { "id": '02', "name": "FedEx Pak" }, { "id": '03', "name": "FedEx Box Other" }, { "id": '04', "name": "FedEx Tube" }, { "id": '06', "name": "FedEx Envelope" }, { "id": '15', "name": "FedEx 10kg Box" }, { "id": '25', "name": "FedEx 25kg Box" }, { "id": '98', "name": " Biological" }, { "id": '99', "name": "High Value" }])
    const getData = () => {
        axios
            .get(API_ROUTES.GET_COUNTRY)
            .then((res) => {
                setCountry(res.data.data)
            })
    }

    useEffect(() => {
        async function GetToken() {
            const auth = await verifyAuth();
            if (auth) {
                setICPC(getICPCFromLocalStorage());
                console.log(userID);
            }
         }
         GetToken();
        // const auth = await verifyAuth();
        // if (auth) {
        //     setICPC(getFirstLastNameFromLocalStorage());
        // }
        getData();
    }, []);

    window.addEventListener("beforeunload", (event) => {
        getData();
        console.log("API call before page reload");
    });

    window.addEventListener("unload", (event) => {
        getData();
        console.log("API call after page reload");
    });

    const selecServiceChange = (event) => {
        setServiceDefault(event.target.value)
    };

    const selecPackageChange = (event) => {
        setPackageDefault(event.target.value)
    };

    const selecCountryChange = (event) => {
        setCountryDefault(event.target.value)
    };

    const onSubmit = async (form_data) => {
        console.log(userID);
        setStep("stage2");
        setLoader(true);
        if (form_data.service == 1) {
           console.log(form_data.service);
            const { data } = await axios({
                method: 'get',
                url: API_ROUTES.GET_OUT_RATE + "?FromCountry=" + form_data.country + '&Weight=' + form_data.weight + '&CustomerICPC=' + userID + '&PackingMaterial=' + form_data.packange,
            });
            if (data.meta?.status) {
                setRate(data.data);
                setLoader(false);
            }
        }
        else {
            const { data } = await axios({
                method: 'get',
                url: API_ROUTES.GET_IN_RATE + "?FromCountry=" + form_data.country + '&Weight=' + form_data.weight + '&CustomerICPC=' + userID + '&PackingMaterial=' + form_data.packange,
            });

            if (data?.meta?.status) {
                setRate(data.data);
                setLoader(false);
               // setStep("stage2");
            }
        }
    }

    const reload = async () => {
        setLoader(false);
        setStep("stage1");
    }


    return (
      <React.Fragment>
        <Header name={{ name: "Welcome to React", group: "shipping" }} />
        <div className="contentswrap">
          <div className="container">
            {step === "stage1" && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-wrap-titles shipping">
                      <h1>Get FedEx Rates.</h1>
                    </div>
                    <div className="form-wrap shipping">
                      <div className="row">
                        <div className="form-floating mb-3 col-md-12">
                          <select
                            onChangeCapture={selecServiceChange}
                            className="form-control"
                            {...register("service", {
                              required: "Please select service",
                            })}
                          >
                            {serviceDefault === "" && (
                              <option value=""> select </option>
                            )}
                            {service.map((item) => (
                              <option value={item.id}> {item.name} </option>
                            ))}
                          </select>

                          {errors.service?.message && (
                            <small className="text-danger">
                              {errors.service.message}
                            </small>
                          )}
                          <label for="floatingInput"> Select Service </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-floating mb-3 col-md-12">
                          <select
                            onChangeCapture={selecPackageChange}
                            className="form-control"
                            {...register("packange", {
                              required: "Please select packange",
                            })}
                          >
                            {packageDefault === "" && (
                              <option value=""> Select </option>
                            )}
                            {packange.map((item) => (
                              <option value={item.id}> {item.name} </option>
                            ))}
                          </select>
                          {errors.packange?.message && (
                            <small className="text-danger">
                              {errors.packange.message}
                            </small>
                          )}
                          <label for="floatingInput"> Package Type </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-floating mb-3 col-md-12">
                          <select
                            onChangeCapture={selecCountryChange}
                            className="form-control"
                            {...register("country", {
                              required: "Please select country",
                            })}
                          >
                            {countryDefault === "" && (
                              <option value=""> Select </option>
                            )}
                            {country.map((item) => (
                              <option value={item.code}> {item.name} </option>
                            ))}
                          </select>

                          {errors.country?.message && (
                            <small className="text-danger">
                              {errors.country.message}
                            </small>
                          )}

                          <label for="floatingInput"> Country </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-floating mb-3 col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            {...register("weight", {
                              required: "Weight is required",
                              pattern: {
                                value: /^(0(\.\d+)?|[1-9]\d*(\.\d+)?)$/,
                                message: "Weight should be a valid",
                              },
                            })}
                            id=""
                            placeholder="Register Form"
                          />
                          {/* errors will return when field validation fails  */}
                          {errors.weight?.message && (
                            <small className="text-danger">
                              {errors.weight.message}
                            </small>
                          )}
                          {/* <input type="text" className="form-control" id="" placeholder="Register Form" /> */}
                          <label for="floatingInput"> Total Weight (kg) </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn_register"
                            id="show_transit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {step === "stage2" && (
              <div className="row">
                <div class="flex justify-center items-center">
                  <Bars
                    height="100vh"
                    width="80"
                    color="#4d148c"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loader}
                  />
                </div>
                {loader === false && (
                  <div className="col-md-12">
                    <div className="form-wrap-titles shipping">
                      <h1> Rates </h1>
                    </div>
                    <h2 class="text-center text-3xl">
                      <svg
                        class="h-5 w-5 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <span class="text-green-500">
                        <div className="flex row-2 justify-center gap-5">
                          
                          <div className=" text-green-500 text-3xl">
                            <label htmlFor="exampleCheck1">
                              USD Rate
                              <NumericFormat
                                value={rete.TOTALUSD}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                style={{
                                  margin: "20px",
                                }}
                              />
                            </label>
                          </div>
                          <div className=" text-green-500 text-3xl">
                            <label htmlFor="exampleCheck1">
                              LKR Rate
                              <NumericFormat
                                value={rete.TOTALLKR}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                style={{
                                  margin: "20px",
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </span>
                    </h2>
                    <div class="flex min-w-0 gap-x-4">
                      <div class="min-w-0 flex-auto text-center">
                        <p class="text-sm  leading-6 text-gray-900 text-4xl pt-3 font-bold">
                          As per IATA standards, the greater of the Dimensional
                          or Actual gross weight will be considered as the
                          chargeable weight.
                        </p>
                        <p class="mt-1 truncate p-5  leading-5 text-gray-500 text-1xl pt-2">
                          
                          Conversion Rate USD
                          <NumericFormat
                            value={rete.CONVRATE}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            style={{
                              margin: "10px",
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <button onClick={reload} className="btn_register">
                        Refresh
                      </button>
                    </div>
                    {/* 
                    <div className="transit-info">
                      <div className="transit-checks">
                        <div className="form-check ">
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            USD Rate
                            <NumericFormat
                              value={rete.TOTALUSD}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              style={{
                                margin: "20px",
                              }}
                            />
                          </label>
                        </div>
                        <div className="form-check text-green-500 text-3xl">
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            LKR Rate
                            <NumericFormat
                              value={rete.TOTALLKR}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={""}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              style={{
                                margin: "20px",
                              }}
                            />
                          </label>
                        </div>
                      </div>

                      <p class=" font-semibold leading-6 text-gray-900 text-sm pt-2">
                        As per IATA standards, the greater of the Dimensional or
                        Actual gross weight will be considered as the chargeable
                        weight.
                      </p>
                      <p class="mt-1 truncate   leading-5 text-gray-500 text-1xl pt-2">
                        Conversion Rate USD:
                        <NumericFormat
                          value={rete.CONVRATE}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={""}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </p>

                      <div className="col-12">
                        <button onClick={reload} className="btn_register">
                          Refresh
                        </button>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <section className="transit-info-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="transit-info">
                                    <p> { rete.TOTALUSD } Here are the rates and delivery dates for shipments sent <br /> Monday, January 16, 2023</p>
                                    <div className="transit-checks">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="" />
                                            <label className="form-check-label" for="exampleCheck1">I want this shipment picked up</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="" />
                                            <label className="form-check-label" for="exampleCheck1">I'm shipping non-standard package(s)</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="transit-rows">
                                    <div className="transit-cols tcol-1">
                                        <h4>17, Tuesday</h4>
                                        <h5>January</h5>
                                    </div>
                                    <div className="transit-cols tcol-2">
                                        <h2>End of Day</h2>
                                        <h3>FedEx International Priority®</h3>
                                    </div>
                                    <div className="transit-cols tcol-3">
                                        <h2>Estimated Price (LKR)</h2>
                                        <h3>158,051.77</h3>
                                        <div className="info-pop">
                                            <button type="button" className="btnpopup" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true" title="
                                
                                <div className='tipwrap'>
                                    <h4>Estimated Price Breakdown (LKR)</h4>
                                    <div className='tip-row'>
                                        <h5>Transportation Charges</h5>
                                        <h6>112,771.17</h6>
                                    </div>
                                    <div className='tip-row'>
                                        <h5>Fuel Surcharge</h5>
                                        <h6>37,861.20</h6>
                                    </div>
                                    <div className='tip-row'>
                                        <h5>Emergency Situation</h5>
                                        <h6>7,419.40</h6>
                                    </div>
                                    <div className='tip-row total'>
                                            <div className='tw-values'>
                                                <h5>Estimated Total</h5>
                                                <p>(VAT included if applicable)</p>
                                            </div>
                                            <h3>158,051.77</h3>
                                        </div>
                             
                                </div>"><img src="assets/img/info-icon.jpg" alt="" /></button>


                                        </div>

                                    </div>
                                    <div className="transit-cols tcol-4">
                                        <a href="" className="selectbtn">SELECT OPTION</a>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="transit-rows selected">
                                    <div className="transit-cols tcol-1">
                                        <h4>17, Tuesday</h4>
                                        <h5>January</h5>
                                    </div>
                                    <div className="transit-cols tcol-2">
                                        <h2>End of Day</h2>
                                        <h3>FedEx International Priority®</h3>
                                    </div>
                                    <div className="transit-cols tcol-3">
                                        <h2>Estimated Price (LKR)</h2>
                                        <h3>158,051.77</h3>
                                        <div className="info-pop">
                                            <button type="button" className="btnpopup" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-html="true" title="
                                
                                <div className='tipwrap'>
                                    <h4>Estimated Price Breakdown (LKR)</h4>
                                    <div className='tip-row'>
                                        <h5>Transportation Charges</h5>
                                        <h6>112,771.17</h6>
                                    </div>
                                    <div className='tip-row'>
                                        <h5>Fuel Surcharge</h5>
                                        <h6>37,861.20</h6>
                                    </div>
                                    <div className='tip-row'>
                                        <h5>Emergency Situation</h5>
                                        <h6>7,419.40</h6>
                                    </div>
                                    <div className='tip-row total'>
                                            <div className='tw-values'>
                                                <h5>Estimated Total</h5>
                                                <p>(VAT included if applicable)</p>
                                            </div>
                                            <h3>158,051.77</h3>
                                        </div>
                             
                                </div>"><img src="assets/img/info-icon.jpg" alt="" /></button>


                                        </div>

                                    </div>
                                    <div className="transit-cols tcol-4">
                                        <a href="" className="selectbtn">SELECTED</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="transit-rows">
                                    <div className="transit-cols tcol-1">
                                        <h4>17, Tuesday</h4>
                                        <h5>January</h5>
                                    </div>
                                    <div className="transit-cols tcol-2">
                                        <h2>End of Day</h2>
                                        <h3>FedEx International Priority®</h3>
                                    </div>
                                    <div className="transit-cols tcol-3">
                                        <h2>Estimated Price (LKR)</h2>
                                        <h3>158,051.77</h3>
                                        <div className="info-pop">
                                            <img src="assets/img/info-icon.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="transit-cols tcol-4">
                                        <a href="" className="selectbtn">SELECT OPTION</a>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                </section> */}
        </div>
        <Footer />
      </React.Fragment>
    );
}

export default ShippingRates;