import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useRef } from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_ROUTES, APP_ROUTES } from '../utils/constants';
import { storeTokenInLocalStorage } from '../lib/common';
import { useForm } from 'react-hook-form';
import { useUser } from '../lib/customHooks';
//import { reactCodeInput } from 'CodeInputField.scss'
import ReactCodeInput from "react-code-input";

const ForgotPassword = () => {

    const [apiError, setApiError] = useState(null);
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const [auth_code, setPinCode] = useState("");
    const [step, setStep] = useState("stage1");
    const [phone, setPhone] = useState(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = async (form_data) => {
        try {
            const { data } = await axios({
                method: 'post',
                url: API_ROUTES.SEND_AUTH,
                data: form_data
            });
            console.log(data.meta.status);

            if (!data?.meta?.status) {
                console.log("stage 1 error");
                setApiError(data?.meta?.status_message);
            } else {
                setPhone(form_data.phone);
                console.log(form_data.phone);
                setStep("stage2");
            }
        }
        catch (err) {
            setApiError('Some error occured during registration');
        }
    };

    const onSubmitVeify = async (form_data) => {
        form_data['auth_code'] = auth_code;
        console.log(form_data);
        try {
            const { data } = await axios({
                method: 'post',
                url: API_ROUTES.FORGET_PASSWORD,
                data: form_data
            });
            console.log(data.meta.status);

        if (!data?.meta?.status) {
            //console.log("stage 1 error");
            setIsPinCodeValid(false);
            setApiError(data?.meta?.status_message);
        } else if(data?.meta?.status) {
            //console.log("stage 2");
            setStep("stage3");
        } else {
            setApiError('Some error occured during registration');
        }
        }
        catch (err) {
            setApiError('Some error occured during registration');
            //  console.log('Some error occured during signing in: ', err);
        }
    };

    const verifySms = async () => {
        try {
            const { data } = await axios({
                method: 'post',
                url: API_ROUTES.VERIFY,
                data: {
                    phone,
                    auth_code
                }
            });
            //console.log(data.meta.status);

            if (!data?.meta?.status) {
                //console.log("stage 1 error");
                setIsPinCodeValid(false);
                setApiError(data?.meta?.status_message);
            } else {
                //console.log("stage 2");
                setStep("stage3");
            }


        }
        catch (err) {
            setApiError('Some error occured during registration');
            //  console.log('Some error occured during signing in: ', err);
        }
    };

    const props = {
        inputStyle: {
            fontSize: '32px',
            width: '38px',
            height: '34px',
            fontFamily: 'monospace',
            margin: '4px',
            paddingLeft: '7px',
        },
    }

    const password = useRef({});
    password.current = watch("password", "");

    const handlePinChange = auth_code => {
        setPinCode(auth_code);
        //setBtnIsPressed(false);
    };

    return (
        <React.Fragment>
            <Header name={{ name: 'Welcome to React' }} />
            <div className="contentswrap">

                {step === 'stage1' &&
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="breadcrumb-wrap reg">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item active">Step 1</li>
                                                <li className="breadcrumb-item" aria-current="page">Personal Information</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-wrap-titles">
                                        <p>Enter your phone number related to your account </p>
                                    </div>

                                    <div className="form-wrap register">

                                        {apiError && <div sh={apiError} className="alert alert-danger" role="alert">
                                            {apiError}
                                        </div>
                                        }

                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="phone" className="form-control" id="phone"  {...register('phone', {
                                                    required: "Phone is required", pattern: {
                                                        value: /^\d{10}$/,
                                                        message:
                                                            "Phone Number must be 10 digit"
                                                    }
                                                })} placeholder="Register Form" />
                                                {errors.phone?.message && (
                                                    <small className="text-danger"  >{errors.phone.message}</small>
                                                )}
                                                <label for="floatingInput">Phone</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <button type="submit" className="btn_register"> Send Verification Code</button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                }

                {step === 'stage2' &&
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmitVeify)}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb-wrap reg">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active">Step 2</li>
                                            <li className="breadcrumb-item" aria-current="page"> Sms Verification</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="form-wrap-titles">
                                <p> Please verify your auth code </p>
                                <ReactCodeInput onChange={handlePinChange} isValid={isPinCodeValid} type='text' fields={6} {...props} />
                            </div>
                        </div>
                        <div className="row">
                        <div className="form-floating mb-3 col-md-12" >
                                                <input type="password" className="form-control" id="password" {...register('password', { required: "Password is required" })} placeholder="Register Form" />
                                                {errors.password?.message && (
                                                    <small className="text-danger" >{errors.password.message}</small>
                                                )}
                                                <label for="floatingInput"> Password</label>
                                            </div>
                        </div>
                        <div className="row">
                        <div className="form-floating mb-3 col-md-12" >
                                                <input type="password" className="form-control" id="confirm_password" {...register('new_password', {
                                                    required: "Password is required", validate: value =>
                                                        value === password.current || "The passwords do not match"
                                                })} placeholder="Register Form" />
                                                {errors.new_password?.message && (
                                                    <small className="text-danger" >{errors.new_password.message}</small>
                                                )}
                                                <label for="floatingInput"> Confirm Password</label>
                                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button onClick={verifySms} className="btn_register"> Verify Auth Code</button>

                            </div>
                        </div>
                        </form>
                    </div>
                }

                {step === 'stage3' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb-wrap reg">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active">Step 3</li>
                                            <li className="breadcrumb-item" aria-current="page"> Registration Success</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="form-wrap-titles">
                            <h1> Success</h1>
                             <p> Registration success please login to your account.</p>
                            </div>
                        </div>
                        <div className="row">
                    
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default ForgotPassword;
