import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const DeliveryStatusFaq = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
            <section className="banner-wrapper" style={{backgroundImage: `url(${"assets/img/faq-banner.png"})`,}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-title">
                            <h1>Frequently asked questions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-wrap faq2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><a href="/faq">FAQ</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Documents</li>
                            </ol>
                        </nav>
                        <h2 className="faqtitle">Common questions asked by customers</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    {/* <!-- mobile search --> */}
                    <div className="faq-search-mobile">
                        <div className="row">
                            <div className="col-12">
                                <div className="search-form">
                                    <input type="text" className="form-control" placeholder="Search your question"></input>
                                    <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- mobile search end --> */}
                    <div className="faq-sidebar">                     
                        <div className="faqwrapper">
                            <div className="faq-link-wrap" data-aos="fade-down">
                            <h2>Documents</h2>
                            <ul>
                                <li> <a href="#f1">What is a Commercial Invoice?</a></li>
                                <li> <a href="#f2">What is a Packing List?</a></li>
                                <li> <a href="#f3">What is a Certificate of Origin?</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="faq-content-col">
                        <div className="faq-search-wrap">
                        <div className="faq-search-dk">
                            <div className="row">
                                    <div className="col-12">
                                        <div className="search-form">
                                        <input type="text" className="form-control" placeholder="Search your question"></input>
                                        <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img></button>
                                        </div>
                                    </div>
                            </div>
                        </div>                     
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-contents faqlist" data-aos="fade-down">
                                        <h2>Documents</h2>  
                                        <div className="quick-faq">
                                        <ul>
                                            <li><a href=""style={{color:'#333',fontSize:28}}>What is a Commercial Invoice?</a></li>
                                            <p>A commercial invoice form is the primary document used for importation control, cargo valuation, and duty determination. It is necessary for all non-document shipments. To prevent customs delays, it is critical to submit correct and specific information.
                                                <br></br>
                                                The form should include:
                                                • The Economic Operators Registration and Identity (EORI) Number.
                                                <br></br>
                                                • Full name and address information for both the shipper and the recipient.
                                                <br></br>
                                                • Contact information for both the sender and the recipient.
                                                <br></br>
                                                • Terms and Conditions of Sale (Incoterms).
                                                <br></br>
                                                • The reason for export.
                                                <br></br>
                                                • A detailed description of the item.
                                                <br></br>
                                                • The nature of the object.
                                                <br></br>
                                                • The purpose of the thing.
                                                <br></br>
                                                • Harmonised Tariff Codes, if available.
                                                <br></br>
                                                • Country or territory of origin (where each item was manufactured).
                                                <br></br>
                                                • Each item's number of units, unit value, and overall value (buying price).
                                                <br></br>
                                                • The number of shipments and their total weight.
                                                <br></br>
                                                • The signature and date of the shipper.
                                                <br></br>
                                                If the item being shipped has no commercial worth, a reasonable market value must be specified. Download a commercial invoice (link)
                                                <br></br>
                                                •	Also include the video on how to fill commercial invoice: 
                                                <br></br>
                                                •	Link: https://www.fedex.com/en-in/new-customer/how-to-fill-out-commercial-invoice.html
                                                <br></br>
                                                •	Different types of shipments need different forms when you’re shipping internationally. To know more, use the phone number and email below to know more details:
                                                <br></br>
                                                •	Phone: + 94 114 522 222 Email: info@fedexlk.com
                                                </p>
                                                <br></br>
                                            <li><a href=""style={{color:'#333',fontSize:28}}>What is a Packing List? </a></li>
                                                <p>A packing list is an optional document that allows shippers to provide information about the contents of a shipment. A packing list may appear to be the same as a commercial invoice, but it is not. A packing list should not and cannot be used in place of a commercial invoice because customs does not utilize it to determine tariffs and fees related to the shipment.
                                                    <br></br>
                                                A packing list can be highly useful even when there is no information regarding the cost or value of the contents of a shipment. Packing lists, for example, are useful when the contents of a shipment are sent to a third party, such as a client or vendor.
                                                Different types of shipments need different forms when you’re shipping internationally. To know more, use the phone number and email below to know more details:
                                                Phone: + 94 114 522 222 Email: info@fedexlk.com
                                                </p>
                                           <br></br> <li><a href="" style={{color:'#333',fontSize:28}}>What is a Certificate of Origin?</a> </li>
                                                <p>A certificate of origin is a document that validates the manufacturing country of the items being sent, and the certificate's requirement is determined by the item's origin and destination. The reasons for the requirement could be established Treaty arrangements, varied duty rates, and preferential duty treatment based on the shipment's origin.
                                                When a certificate is required, the rules change regularly. Before shipment, double-check your exact requirements.
                                                <br></br>
                                                Different types of shipments need different forms when you’re shipping internationally. To know more, use the phone number and email below to know more details:
                                                Phone: + 94 114 522 222 Email: info@fedexlk.com
                                                </p>
                                        </ul>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        
                                        </div>                                 
                                    </div>                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
    );
  }
  
  export default DeliveryStatusFaq;