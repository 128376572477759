import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from 'react';
import axios from "axios";
import React from 'react';
import { API_ROUTES } from "../utils/constants";
import { useForm } from "react-hook-form";

const ContactUs = () => {
   const [country, setCountry] = React.useState([])
   const [apiError, setApiError] = useState(null);
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm();

   const getData = () => {
      axios
         .get(API_ROUTES.GET_COUNTRY)
         .then((res) => {
            setCountry(res.data.data)
         })
   }
   useEffect(() => {
      getData();
   }, []);

   window.addEventListener("beforeunload", (event) => {
      getData();
      console.log("API call before page reload");
   });

   window.addEventListener("unload", (event) => {
      getData();
      console.log("API call after page reload");
   });

   const onSubmit = async (form_data) => {
      try {
         const { data } = await axios({
            method: 'post',
            url: API_ROUTES.ContactUs,
            data: form_data
         });
      }
      catch (err) {
         setApiError('Some error occured during registration');
         //  console.log('Some error occured during signing in: ', err);
      }
   }

   return (
      <React.Fragment>
         <Header name={{ name: 'Welcome to React', group: 'support' }} />
         <div className="contentswrap">
            <section className="banner-wrapper" style={{ backgroundImage: `url(${"assets/img/contact-banner.jpg"})`, }}>
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="banner-title">
                           <h1>Contact us</h1>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="breadcrumb-wrap bann">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item"><a href="/">HOME</a></li>
                              <li className="breadcrumb-item active" aria-current="page">CONTACT US</li>
                           </ol>
                        </nav>
                     </div>
                  </div>
                  <div className="col-md-12">
                     <div className="sub-titles contacts text-center" data-aos="fade-down">
                        <h1>Reach us</h1>
                        {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p> */}
                     </div>
                  </div>
               </div>
            </section>
            <section className="contact-info">
               <div className="container">
                  <div className="row">
                     <div className="col-md-6">
                        <div className="c-info-col1">
                           <div className="quick-info-row">
                              <h4>General Contact</h4>
                              <a href="tel:(+94)114 522 222">(+94)114 522 222</a>
                           </div>
                           <div className="quick-info-row">
                              <h4>Email address</h4>
                              <a href=": info@fedexlk.com">info@fedexlk.com</a>
                           </div>
                           <div className="location-wrap">
                              <h5>Advantis Express (Pvt) Ltd.</h5>
                              <p>46/56, <br /> Navam Mawatha,<br /> Colombo -02, Sri Lanka.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6">
                        <div className="c-info-map">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.7574363974127!2d79.86150631409541!3d6.919575320353379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25972acde2e99%3A0xe5194213ea86d86b!2sHayleys%20Advantis%20Limited!5e0!3m2!1sen!2slk!4v1679478375435!5m2!1sen!2slk" width="100%" height="740" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                           <a href="https://goo.gl/maps/EamqHTmnf2U2RFtL9" target="_blank" className="btn-getdirection">GET DIRECTION</a>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <div className="col-md-12">
               <div className="sub-titles contacts text-center" data-aos="fade-down">
                  <h1>Reach us</h1>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
               </div>
            </div>
            <div className="col-md-12">
               <div className="sub-titles contacts text-center" data-aos="fade-down">
                  <h1>Reach us</h1>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
               </div>
            </div>
            <section className="branch">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <div className="branch-wrap">
                           <h5>Branches islandwide</h5>

                           <div class="grid grid-cols-4 gap-4">
                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express – Corporate Office </h6>

                                    <p>46/56, Lanlib Building,<br /> Nawam Mawatha,<br /> Colombo 2, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">

                                    <a href="tel:+94 11 4 522 222" className="call">+94 11 4 522 222</a>
                                 </div>
                            </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6>FedEx Express – Purple Hub	Seeduwa </h6>

                                    <p> No:387/12, Abans Road,<br /> Seeduwa,<br /> Seeduwa, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                  
                                    <a href="tel:+94 11 4 830 561" className="call"> +94 11 4 830 561 </a>
                                 </div>


                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express – Colombo Station	Orugodawatta	 </h6>

                                    <p> 235/12, <br />  Avissawella Road,<br /> Orugodawatta, Sri Lanka</p>

                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel: +94 11 4 522 222" className="call"> +94 11 4 522 222</a>
                                 </div>


                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6>FedEx Express - Kollupitiya </h6>
                                    <p> WSC	Kollupitiya 340,	 <br /> Galle Road,<br /> Colombo 3, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 11 4 384 792" className="call">+94 11 4 384 792</a>
                                 </div>

                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express- Mount Lavinia </h6>
                                    <p> WSC	Mount Lavinia	No 90/A,<br /> Galle Road,<br /> Mount Lavinia, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 74 233 8748" className="call">+94 74 233 8748</a>
                                 </div>

                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6>FedEx Express - Wattala WSC</h6>
                                    <p>No 130,<br /> Negombo Road,<br /> Wattala, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 741 045 049" className="call">+94 741 045 049</a>
                                 </div>
                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6>FedEx Express - Biyagama Station</h6>
                                    <p>19 - B,<br /> B.O.I. Complex. E.P.Z</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 114 812 834" className="call">+94 114 812 834</a>
                                 </div>
                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express - Panadura WSC </h6>
                                    <p> 557/B,<br /> Galle Road,<br /> Panadura, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 38 4 281 085" className="call">+94 38 4 281 085</a>
                                 </div>

                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6>FedEx Express – Gate Way Office	Katunayake </h6>
                                    <p> 531/2, <br />  Negombo Rd,<br /> Seeduwa, Sri Lanka</p>
                                 </div>
                                 {/* <div className="branch-inner">
                                 <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div> */}

                              </div>
                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express - Gem Office	Kollupitiya	 </h6>
                                    <p> Level 4<br />  Macksons Tower, Alfred House Garden, <br />  Colombo 3, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 773 541 296" className="call">+94 773 541 296</a>
                                 </div>

                              </div>

                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Logistics	Colombo</h6>
                                    <p> 46/12, <br /> Sayuru Sevana Building, Nawam Mawatha,<br /> Colombo 2, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94 777273121" className="call"> +94 777273121</a>
                                 </div>

                              </div>

                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express - Kandy WSC </h6>
                                    <p> No.323,<br /> Peradeniya Rd,<br /> Kandy, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94  815 772 222" className="call">+94 815 772 222</a>
                                 </div>

                              </div>

                              <div>

                                 <div className="branch-inner">
                                    <h6> FedEx Express - Kurunegala WSC	 </h6>
                                    <p> 155, <br /> Kandy Road, <br /> Kurunegala, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <a href="tel:+94  37 5 772 222" className="call">+94  37 5 772 222</a>
                                 </div>

                              </div>




                           </div>
                           {/* <div className="branche-slider">
                           <div className="branch-block">
                      
                              <div className="branch-name">
                                 <h6>FedEx Express – Corporate Office</h6>
                              </div>
                    
                              	
                           </div>
                           <div className="branch-block">
                               
                              <div className="branch-name">
                                 <h6>FedEx Express – Colombo Station</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>235/12,,<br/> Avissawella Road,<br/> Orugodawatta, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 11 4 522 222" className="call">+94 11 4 522 222</a>
                                 </div>
                                 <a href="https://www.google.com/maps/dir//340+Galle+Rd,+Colombo+00300/@6.9095489,79.7677621,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ae259aed98581a5:0xb9f73e9bf1557991!2m2!1d79.850164!2d6.909556?entry=ttu" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                              
                              <div className="branch-name">
                                 <h6>FedEx Express – Purple Hub</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>

                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                              
                              <div className="branch-name">
                                 <h6>FedEx Express - Biyagama Station</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                            
                              <div className="branch-name">
                                 <h6>FedEx Express - Kollupitiya ROC</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                              
                              <div className="branch-name">
                                 <h6>FedEx Express - Kandy ROC</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                              
                              <div className="branch-name">
                                 <h6>FedEx Express - Kurunegala ROC</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                              
                              <div className="branch-name">
                                 <h6>FedEx Express - Gem Office</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                               
                              <div className="branch-name">
                                 <h6>FedEx Express – Gate Way Office</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                           <div className="branch-block">
                              
                              <div className="branch-name">
                                 <h6>FedEx Logistics</h6>
                              </div>
                              <div className="branch-info">
                                 <div className="branch-inner">
                                    <h4>Address</h4>
                                    <p>PB 548, 408A,<br/> Nungamugoda,<br/> Kelaniya, Sri Lanka</p>
                                 </div>
                                 <div className="branch-inner">
                                    <h4>Phone</h4>
                                    <a href="tel:+94 112 168 300" className="call">+94 112 168 300</a>
                                 </div>
                                 <a href="" className="branch-link">GET DIRECTION</a>
                              </div>
                           </div>
                        </div> */}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
             
         </div>

         <Footer />
      </React.Fragment>
   );
}

export default ContactUs;