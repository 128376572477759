import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useRef } from 'react';
import axios from 'axios';

const Paymentsuccess = () => {
        return (
          <React.Fragment>
            <Header name={{ name: "Advantis Express" }} />
            <div class="contentswrap">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="breadcrumb-wrap reg">
                      <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <br></br>
                          <br></br>
                          <div class="form-wrap-titles">
                            <a href="" target="_blank">
                              <img
                                src="assets/img/ok.jpg"
                                alt=""
                                style={{
                                  fill: "#28d006",
                                  padding: "center",
                                  width: "90px",
                                  height: "90px",
                                }}
                              ></img>
                            </a>
                          </div>
                        </ol>
                      </nav>
                    </div>
                  </div>

                  <div class="form-wrap-titles">
                    <h1>Payment Success</h1>
                    <p> Thank you for the payment.</p>
                  </div>
                </div>
                <div class="row"></div>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer />
          </React.Fragment>
        );
}

export default Paymentsuccess;