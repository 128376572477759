import { API_ROUTES } from '../utils/constants';
import axios from 'axios';
export function storeTokenInLocalStorage(token, first_name,last_name,fedexid = 104040909,tokenType = 'Bearer') {
  localStorage.setItem('token', tokenType+' '+token);
  localStorage.setItem('first_name', first_name);
  localStorage.setItem('last_name', last_name);
  localStorage.setItem('fedexid', fedexid);
  
}

export function getTokenFromLocalStorage() {
 // console.log(localStorage.getItem('token'));
  return localStorage.getItem('token');
}

export async function verifyAuth(){
  try {
    const token = getTokenFromLocalStorage();
    if (!token) {
      return false;
    }
    const response = await axios({
      method: 'POST',
      url: API_ROUTES.VALIDATE,
      headers: {
        Authorization: `${token}`
      }
    });
    return response.data.data;
  }
  catch (err) {
    console.log('getAuthenticatedUser, Something Went Wrong', err);
    return false;
  }
}

export async function verifyPortal(){
  try {
    const token = getTokenFromLocalStorage();
  
    const response = await axios({
      method: 'POST',
      url: API_ROUTES.PORTAL,
      headers: {
        Authorization: `${token}`
      }
    });
    return response.data.data;
  }
  catch (err) {
    console.log('Portal Error, Something Went Wrong', err);
    return false;
  }
}



export function getFirstLastNameFromLocalStorage() {
   if(localStorage.getItem('token') !== null){
    return localStorage.getItem('first_name') +' '+ localStorage.getItem('last_name') ;
   } else {
    console.log("null")
    return null;
   }
 
 }

 export function getICPCFromLocalStorage() {
  if(localStorage.getItem('token') !== null){
   return localStorage.getItem('fedexid');
  } else {
  // console.log("null")
   return 104040909;
  }

}

 export function remoteToken(){
  localStorage.removeItem("token");
  localStorage.removeItem("first_name");
  localStorage.removeItem("last_name");
 }
 

export async function getAuthenticatedUser() {
  const defaultReturnObject = { authenticated: false, user: null };
  try {
    const token = getTokenFromLocalStorage();
    if (!token) {
      return defaultReturnObject;
    }
    const response = await axios({
      method: 'POST',
      url: API_ROUTES.GET_USER,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const { authenticated = false } = response.data;
    return authenticated ? response.data : false;
  }
  catch (err) {
    console.log('getAuthenticatedUser, Something Went Wrong', err);
    return defaultReturnObject;
  }
}