import React from "react";
import {
  DocumentTextIcon,
  DocumentPlusIcon,
  DocumentCheckIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";

const Sidebar = () => {
  return (
    <>
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        class="  top-30  inline-flex items-center pt-5 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span class="sr-only">Open sidebar</span>
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="logo-sidebar"
        class="fixed top-0 left-0 z-40 w-64 h-screen  transition-transform -translate-x-full sm:translate-x-0 bg-slate-200"
        aria-label="Sidebar"
      >
        <div class="h-full  pt-3 pl-8 overflow-y-auto bg-gray-50 dark:bg-gray-800">
          <ul class="space-y-2 pt-20 mt-10 font-medium">
            {/* <img
              src="assets/img/advantis-express.png"
              alt="hello"
              className="  w-60 h-20 pr-3 mb-4 "
            />  */}
            <li>
              <a
                href="/dashboard"
                class="flex items-center p-2 text-blue rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg
                  class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span class="ms-3">Dashboard</span>
              </a>
            </li>
            <li>
              <a
                href="/open-invoice"
                class="flex items-center p-2 text-blue rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <DocumentTextIcon className="h-5 w-5  text-gray-800" />
                <span class="flex-1 ms-3 whitespace-nowrap">Open Invoices</span>
              </a>
            </li>
            {/* <li>
              <a
                href="/Paid-closed-invoices"
                class="flex items-center p-2 text-blue rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <DocumentCheckIcon className="h-5 w-5  text-gray-800" />
                <span class="flex-1 ms-3 whitespace-nowrap">
                  {" "}
                  Paid Invoices
                </span>
              </a>
            </li> */}
            {/* <li>
              <a
                href="/past-due-invoices"
                class="flex items-center p-2 text-blue rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <DocumentPlusIcon className="h-5 w-5  text-gray-800" />
                <span class="flex-1 ms-3 whitespace-nowrap"> Past Due </span>
              </a>
            </li>
            <li>
              <a
                href="/Paid-closed-invoices"
                class="flex items-center p-2 text-blue rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <DocumentCheckIcon className="h-5 w-5  text-gray-800" />
                <span class="flex-1 ms-3 whitespace-nowrap"> Paid Colse</span>
              </a>
            </li>
            <li>
              <a
                href="/in-dispute-invoices"
                class="flex items-center p-2 text-blue rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <ExclamationCircleIcon className="h-5 w-5  text-gray-800" />
                <span class="flex-1 ms-3 whitespace-nowrap">In Dispute</span>
              </a>
            </li> */}
          </ul>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
