import { Link , useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROUTES } from '../utils/constants';
import { storeTokenInLocalStorage, verifyAuth, getFirstLastNameFromLocalStorage, remoteToken, getTokenFromLocalStorage } from '../lib/common';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faUser } from "@fortawesome/free-solid-svg-icons";
const LoginForm = () => {
   const show = useState(false);
   const [phone, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const error = useState('');
   const [user2, setUser] = useState(null);
   const navigate = useNavigate();

   useEffect(() => {
      async function GetToken() {
         const auth = await verifyAuth();
         if (auth) {
            setUser(getFirstLastNameFromLocalStorage());
         }
      }
      GetToken();
   }, []);

   const handleDropdownMenuClick = (e) => {
      e.stopPropagation();
   };

   const logIn = async () => {
      try {
         const { data } = await axios({
            method: 'post',
            url: API_ROUTES.SIGN_IN,
            data: {
               phone,
               password
            }
         });

         if (!data?.authentication_data?.access_token) {
            toast.error("Some error occured during signing in")
            return;
         }
         storeTokenInLocalStorage(data?.authentication_data?.access_token, data?.user_data?.first_name, data?.user_data?.last_name , data?.user_data?.fedexid);
         setUser(data?.user_data?.first_name + " " + data?.user_data?.last_name);
         toast.success("Success:")
         window.location.reload(); 
      }
      catch (err) {
         toast.error("Some error occured during signing in")
      }
   }

   const logOut = async () => {
      try {

         let config = {
            'Authorization': getTokenFromLocalStorage()
         }

         console.log(API_ROUTES.SIGN_OUT);
         const { data } = await axios({
            method: 'post',
            url: API_ROUTES.SIGN_OUT,
            headers: config
         });

         if (data?.meta?.status) {
            remoteToken();
            toast.success("Success:")
            setUser(null);
            navigate("/");
         }

      }
      catch (err) {
         console.log('Some error occured during signing in: ', err);
      }
   }

   if (user2 === null) {
      return (
        <nav className="navbar navbar-expand-lg navbar-light headerlogin">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle loglink"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="true"
              >
              Sign Up/Log In
              </a>
              <ul
                className="dropdown-menu dropdown-menu-lg-end login-form"
                onClick={handleDropdownMenuClick}
                aria-labelledby="navbarDropdownMenuLink"
                show={show}
              >
                <li>
                  <div className="dropdown-menu formwrap">
                    <h3>Login to your account</h3>
                    {error && <p classNameName="error">{error}</p>}
                    <div className="login-fields">
                      <input
                        type="text"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Mobile Number/ICPC"
                      />
                    </div>
                    <div className="login-fields password-wrap">
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                      />
                      <button onClick={logIn} className="btn login">
                        LOGIN
                      </button>
                    </div>

                    <div className="login-fields">
                      <div className="forgot-password-wrap">
                        <Link to="/forgot-password" className="forgot-password">
                          Forgot username and password{" "}
                        </Link>
                        <Link
                          to="/register"
                          className="forgot-password float-right"
                        >
                          {" "}
                          Register
                        </Link>
                      </div>
                    </div>
                    <div className="login-fields">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="dropdownCheck2"
                        />
                        <label
                          className="form-check-label"
                          for="dropdownCheck2"
                        >
                          Remember my user ID
                        </label>
                      </div>
                    </div>
                    <div className="create-acc"></div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      );
   } else {
      return (<nav className="navbar navbar-expand-lg navbar-light headerlogin">
         <span className="text-white mr-sm-2">Hi  {user2}  &nbsp; </span>
         <button type="button ml-3" onClick={logOut} class="btn btn-light">Log Out</button>
      </nav>);
   }
}

export default LoginForm;