import React from 'react'
import Sidebar from '../components/SideBar'
import Header from '../components/Header';
import Footer from '../components/Footer';

const Dashboard = () => {
  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <div className="flex flex-col md:flex-row  pt-20 max-w-[1350px] mx-auto">
    
          <Sidebar />
      
        <div className="flex flex-col pl-6 pb-6">
          <h2 className="text-3xl pt-4 pl-4 md:pl-12 md:pr-8">HI DEVIN!</h2>

          {/* account Summary */}
          <p className="text-3xl inset-x-0 top-0 p-6">
            <h2 inset-x-0 top-0>
              Account Summary
            </h2>
          </p>
          <div className="text-left">
            <p className="text-gray-500 mt-4">Primary Account :</p>
            <p className="text-gray-500 mt-4">Original Charges :</p>
            <p className="text-gray-500 mt-4">Past Due :</p>
            <p className="text-gray-500 mt-4">In Dispute :</p>
            <p className="text-gray-500 mt-4">Payments & Adjustments :</p>
            <p className="text-gray-500 mt-4">Current Account Balance :</p>
          </div>
        </div>

        {/* Menu box */}
        <div className="flex flex-col justify-center px-4 md:pl-10">
          <div className="grid grid-cols-1 md:grid-cols-2 ">
            <div>
              <button className="border size-40 w-full md:w-80 bg-purple hover:bg-org text-white flex flex-col items-center justify-center">
                <a href="/open-invoice">
                  <img src="assets/img/invoice3.png" alt="" />
                </a>
                <div className="pt-3">OPEN INVOICES</div>
              </button>
            </div>
            <div>
              <button className="border size-40 w-full md:w-80 bg-purple hover:bg-org text-white flex flex-col items-center justify-center">
                <a href="/past-due-invoices">
                  <img src="assets/img/invoice1.png" alt="" />
                </a>
                <div className="pt-3">PAST DUE</div>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 ">
            <div>
              <button className="border size-40 w-full md:w-80 bg-purple hover:bg-org text-white flex flex-col items-center justify-center">
                <a href="/paid-closed-invoices">
                  <img src="assets/img/invoice2.png" alt="" />
                </a>
                <div className="pt-3">PAID CLOSE</div>
              </button>
            </div>
            <div>
              <button className="border size-40 w-full md:w-80 bg-purple hover:bg-org text-white flex flex-col items-center justify-center">
                <a href="/in-dispute-invoices">
                  <img src="assets/img/invoice4.png" alt="" />
                </a>
                <div className="pt-3">IN DISPUTE</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Primary Account */}

      <div className="overflow-x-auto max-w-[1350px] mx-auto">
        <h2 className="text-3xl pt-4 pl-4 md:pl-12 md:pr-8 pb-6">
          Primary Account
        </h2>

        <table className="table-auto border-b-gray w-full">
          <thead className="bg-gray">
            <tr>
              <th className="border-b px-4 py-2 font-normal">Currency</th>
              <th className="border-b px-4 py-2 font-normal">0-9 days</th>
              <th className="border-b px-4 py-2 font-normal">9-15 days</th>
              <th className="border-b px-4 py-2 font-normal">16-30 days</th>
              <th className="border-b px-4 py-2 font-normal">31-60 days</th>
              <th className="border-b px-4 py-2 font-normal">61-90 days</th>
              <th className="border-b px-4 py-2 font-normal">91+ days</th>
              <th className="border-b px-4 py-2 font-normal">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
              <td className="px-4 py-2">Data </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
