import Header from "../../components/Header";
import Footer from "../../components/CustomerPortal/Footer";
import Sidebar from "../../components/CustomerPortal/SideBar";
import React from "react";
import axios from "axios";
import { useState } from "react";
import { API_ROUTES } from "../../utils/constants";
import { toast } from "react-toastify";

const PaymentGateway = () => {
  const [apiError, setApiError] = useState(null);
  const [step, setStep] = useState("stage1");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [auth_code, setPinCode] = useState("");
  

  const [formData, setFormData] = useState({
    amount: "11",
    ref_number: "22",
    name_cus: "aa",
    company: "aa",
    address: "ww",
    postal_code: "22",
    city: "dd",
    phone: "123456789",
    email: "sd2@gmail.com",
  });

  const onSubmit = async () => {
    try {
      let { data } = await axios({
        method: "post",
        url: API_ROUTES.PAYMENT,
        data: formData,
      });

      if (!data?.meta?.status) {
        toast.error("Error");
        setApiError(data?.meta?.status_message);
      } else {
        toast.success("Success:");
        setStep("stage2");
        let invoice = { invoice_id: data.data.id };
        data = await axios({
          method: "post",
          url: API_ROUTES.HNBGATEWAY,
          data: invoice,
        });
await setPaymentRequest(data.data.data);      }
    } catch (err) {
      setApiError("Some error occurred during registration");
      toast.error("Error:");
    }
  };

  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div class="p-4 sm:ml-64">
        <div class="p-4 rounded-lg  ">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg "></div>
          {step === "stage1" && (
            <div className="space-y-6">
              <div class="flex justify-center">
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <div class="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <div className="carrer-row flex flex-col md:flex-row p-10">
                      <div className="items-right rounded bg-gray-50 dark:bg-gray-800 mr-7">
                        <h6 className="text-2xl">confirm Your Payment</h6>
                        <div class="grid justify-center">
                          <p className="flex text-gray-500 mt-4">Invoice No:</p>
                          <p className="flex text-gray-500 mt-4">Account No:</p>
                          <p className="flex text-gray-500 mt-4">
                            Payment Amount:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-10 flex justify-between">
                <a href="/payment-method">
                  <button
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    <div className="flex flex-nowrap ">
                      <div className="flex items-center pr-2">
                        <svg
                          class="w-3 h-3 ms-2 sm:ms-4 transform rotate-180 "
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 12 10"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m7 9 4-4-4-4M1 9l4-4-4-4"
                          />
                        </svg>
                      </div>
                      BACK
                    </div>
                  </button>
                </a>

                <button
                  onClick={onSubmit}
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  <div className="flex flex-nowrap ">
                    NEXT
                    <div className="flex items-center">
                      <svg
                        class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 12 10"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m7 9 4-4-4-4M1 9l4-4-4-4"
                        />
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )}
          {step === "stage2" && paymentRequest && (
            <div className=" pt-4">
              <div className="row">
                <form
                  id="paymentform"
                  action="https://secureacceptance.cybersource.com/pay"
                  method="post"
                >
                  <input
                    type="hidden"
                    id="access_key"
                    name="access_key"
                    value={paymentRequest.access_key}
                  />
                  <input
                    type="hidden"
                    id="profile_id"
                    name="profile_id"
                    value={paymentRequest.profile_id}
                  />
                  <input
                    type="hidden"
                    id="transaction_uuid"
                    name="transaction_uuid"
                    value={paymentRequest.transaction_uuid}
                  />
                  <input
                    type="hidden"
                    id="signed_field_names"
                    name="signed_field_names"
                    value={paymentRequest.signed_field_names}
                  />
                  <input
                    type="hidden"
                    id="unsigned_field_names"
                    name="unsigned_field_names"
                    value={paymentRequest.unsigned_field_names}
                  />
                  <input
                    type="hidden"
                    id="signed_date_time"
                    name="signed_date_time"
                    value={paymentRequest.signed_date_time}
                  />
                  <input
                    type="hidden"
                    id="locale"
                    name="locale"
                    value={paymentRequest.locale}
                  />
                  <input
                    type="hidden"
                    id="transaction_type"
                    name="transaction_type"
                    value={paymentRequest.transaction_type}
                  />
                  <input
                    type="hidden"
                    id="reference_number"
                    name="reference_number"
                    value={paymentRequest.reference_number}
                  />
                  <input
                    type="hidden"
                    id="amount"
                    name="amount"
                    value={paymentRequest.amount}
                  />
                  <input
                    type="hidden"
                    id="currency"
                    name="currency"
                    value={paymentRequest.currency}
                  />
                  <input
                    type="hidden"
                    id="bill_to_forename"
                    name="bill_to_forename"
                    value={paymentRequest.bill_to_forename}
                  />
                  <input
                    type="hidden"
                    id="bill_to_surname"
                    name="bill_to_surname"
                    value={paymentRequest.bill_to_surname}
                  />
                  <input
                    type="hidden"
                    id="bill_to_email"
                    name="bill_to_email"
                    value={paymentRequest.bill_to_email}
                  />
                  <input
                    type="hidden"
                    id="bill_to_address_line1"
                    name="bill_to_address_line1"
                    value={paymentRequest.bill_to_address_line1}
                  />
                  <input
                    type="hidden"
                    id="bill_to_address_line2"
                    name="bill_to_address_line2"
                    value={paymentRequest.bill_to_address_line2}
                  />
                  <input
                    type="hidden"
                    id="bill_to_address_country"
                    name="bill_to_address_country"
                    value={paymentRequest.bill_to_address_country}
                  />
                  <input
                    type="hidden"
                    id="bill_to_address_city"
                    name="bill_to_address_city"
                    value={paymentRequest.bill_to_address_city}
                  />
                  <input
                    type="hidden"
                    id="signature"
                    name="signature"
                    value={paymentRequest.signature}
                  />
                  <div className="flex justify-center pt-5">
                    <button
                      type="submit"
                      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Confirm Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {step === "stage3" && (
            <div class="container">
              <div class="grid grid-cols-12">
                <div class="col-span-12">
                  <div class="breadcrumb-wrap reg">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item active">Step 3</li>
                        <li class="breadcrumb-item" aria-current="page">
                          Payment Success
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div class="col-span-12 form-wrap-titles">
                  <h1>Success</h1>
                  <p>Your Payment was Successfully Transferred.</p>
                </div>
              </div>
              <div class="row"></div>
            </div>
          )}
          {step === "stage4" && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="breadcrumb-wrap reg">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active">Step 3</li>
                        <li className="breadcrumb-item" aria-current="page">
                          Payment Not Success
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="form-wrap-titles">
                  <h1> Not Success</h1>
                  <p> Your Payment was Not Successfully Tranfered.</p>
                </div>
              </div>
              <div className="row"></div>
            </div>
          )}{" "}
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentGateway;
