import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { API_ROUTES } from '../utils/constants';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
const Payment = () => {

    const [apiError, setApiError] = useState(null);
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const [auth_code, setPinCode] = useState("");
    const [step, setStep] = useState("stage1");
    const [paymentRequest, setPaymentRequest] = useState([])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = async (form_data) => {
        try {
            let { data } = await axios({
                method: 'post',
                url: API_ROUTES.PAYMENT,
                data: form_data
            });

            if (!data?.meta?.status) {
                toast.error("Error")
                setApiError(data?.meta?.status_message);
            } else {
                toast.success("Success:")
                setStep("stage2");
                let invoice = { 'invoice_id': data.data.id }
                data = await axios({
                    method: 'post',
                    url: API_ROUTES.HNBGATEWAY,
                    data: invoice
                });
                await setPaymentRequest(data.data.data)
            }
        }
        catch (err) {
            setApiError('Some error occured during registration');
            toast.error("Error:")
        }
    };

    return (
        <React.Fragment>
            <Header name={{ name: 'Welcome to React', group: 'payment' }} />
            <div className="contentswrap">

                {step === 'stage1' &&
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="breadcrumb-wrap reg">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item active">Step 1</li>
                                                <li className="breadcrumb-item" aria-current="page">Basic Information</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-wrap-titles">
                                        <h1> Online Payment, Let’s get started.</h1>
                                    </div>

                                    <div className="form-wrap register">

                                        {apiError && <div sh={apiError} className="alert alert-danger" role="alert">
                                            {apiError}
                                        </div>
                                        }
                                        <div className="row g-3">
                                            <label for="formGroupExampleInput" class="form-label"> Payment Information</label>
                                            <div className="form-floating mb-3 col-md-6" >
                                                <input type="text" className="form-control" {...register('amount', {
                                                    required: "Amount is required",
                                                    pattern: {
                                                        value: /^\d+(\.\d{1,2})?$/,
                                                        message:"Please enter a valid amount"
                                                    }
                                                })} id="" placeholder="Register Form" />
                                                {/* errors will return when field validation fails  */}
                                                {errors.amount?.message && (
                                                    <small className="text-danger"  >{errors.amount.message}</small>
                                                )}
                                                <label for="floatingInput">Amount </label>
                                            </div>
                                            <div className="form-floating mb-3 col-md-6">
                                                <input type="text" className="form-control" {...register('ref_number', {
                                                    required: "AWB Number is required",
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: "Please enter a valid AWB / Invoice Number"
                                                    }
                                                })} placeholder="Register Form" />
                                                {/* errors will return when field validation fails  */}
                                                {errors.ref_number?.message && (
                                                    <small className="text-danger"  >  {errors.ref_number.message}</small>
                                                )}
                                                <label for="floatingInput"> AWB / Invoice Number </label>
                                            </div>
                                        </div>
                                        <div className="row g-3">
                                            <label for="formGroupExampleInput" class="form-label">Personal Information</label>
                                            <div className="form-floating mb-3 col-md-6" >
                                                <input type="text" className="form-control" {...register('name_cus', {
                                                    required: "Name is required",
                                                })} id="" placeholder="Register Form" />
                                                {/* errors will return when field validation fails  */}
                                                {errors.name_cus?.message && (
                                                    <small className="text-danger"  >{errors.name_cus.message}</small>
                                                )}
                                                <label for="floatingInput">Name</label>
                                            </div>
                                            <div className="form-floating mb-3 col-md-6">
                                                <input type="text" className="form-control" {...register('company', {
                                                    required: "Company is required",
                                                })} placeholder="Register Form" />
                                                {/* errors will return when field validation fails  */}
                                                {errors.company?.message && (
                                                    <small className="text-danger"  >  {errors.company.message}</small>
                                                )}
                                                <label for="floatingInput">Company</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="text" className="form-control" id="address" placeholder="Register Form"  {...register('address', {
                                                    required: "Address is required",
                                                    pattern: {
                                                        value: /^[A-Za-z0-9\s,.#\/]+$/,
                                                        message:"Please enter a valid address"
                                            }    })} />
                                                {errors.address?.message && (
                                                    <small className="text-danger"  >{errors.address.message}</small>
                                                )}
                                                <label for="floatingInput">Address</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="text" className="form-control"   {...register('postal_code', {
                                                    required: "Postal Code is required",
                                                    pattern: {
                                                        value: /^[0-9]{5}$/,
                                                        message:"Please enter a valid postal code"
                                                    }
                                                })} placeholder="Register Form" />
                                                {errors.postal_code?.message && (
                                                    <small className="text-danger"  >{errors.postal_code.message}</small>
                                                )}
                                                <label for="floatingInput">Postal Code</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="text" className="form-control" {...register('city', {
                                                    required: "City is required",
                                                    pattern: {
                                                        value: /^[A-Za-z\s]+$/, 
                                                        message: "City must be a valid City name"
                                                    }
                                                })} placeholder="Register Form" />
                                                {errors.city?.message && (
                                                    <small className="text-danger" >{errors.city.message}</small>
                                                )}
                                                <label for="floatingInput"> City</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="text" className="form-control" {...register('phone', {
                                                    required: "Phone is required", pattern: {
                                                        value: /^(0\d{9}|94\d{9})$/,
                                                        message:
                                                            "Phone Number must be a valid"
                                                    }
                                                })} placeholder="Register Form" />
                                                {errors.phone?.message && (
                                                    <small className="text-danger"  >{errors.phone.message}</small>
                                                )}
                                                <label for="floatingInput"> Phone</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="email" className="form-control" {...register('email', {
                                                    required: "Email is required",
                                                    validate: {
                                                        matchPattern: (v) =>
                                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                            "Email address must be a valid address",
                                                    },
                                                })} placeholder="Register Form" />
                                                {errors.email?.message && (
                                                    <small className="text-danger" >{errors.email.message}</small>
                                                )}
                                                <label for="floatingInput"> Email </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <button type="submit" className="btn_register">ENTER PAYMENT DETAILS</button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                }

                {step === 'stage2' &&
                    <div className="container pt-4">
                        <div className="col-12">
                            <h1> COnfirm HNB payment Gateway</h1>
                        </div>
                        <div className="row">
                            <form

                                id="paymentform"
                                action="https://secureacceptance.cybersource.com/pay"
                                method="post"
                            >
                                <input
                                    type="hidden"
                                    id="access_key"
                                    name="access_key"
                                    value={paymentRequest.access_key}
                                />
                                <input
                                    type="hidden"
                                    id="profile_id"
                                    name="profile_id"
                                    value={paymentRequest.profile_id}
                                />
                                <input
                                    type="hidden"
                                    id="transaction_uuid"
                                    name="transaction_uuid"
                                    value={paymentRequest.transaction_uuid}
                                />
                                <input
                                    type="hidden"
                                    id="signed_field_names"
                                    name="signed_field_names"
                                    value={paymentRequest.signed_field_names}
                                />
                                <input
                                    type="hidden"
                                    id="unsigned_field_names"
                                    name="unsigned_field_names"
                                    value={paymentRequest.unsigned_field_names}
                                />
                                <input
                                    type="hidden"
                                    id="signed_date_time"
                                    name="signed_date_time"
                                    value={paymentRequest.signed_date_time}
                                />
                                <input
                                    type="hidden"
                                    id="locale"
                                    name="locale"
                                    value={paymentRequest.locale}

                                />
                                <input
                                    type="hidden"
                                    id="transaction_type"
                                    name="transaction_type"
                                    value={paymentRequest.transaction_type}
                                />
                                <input
                                    type="hidden"
                                    id="reference_number"
                                    name="reference_number"
                                    value={paymentRequest.reference_number}
                                />
                                <input
                                    type="hidden"
                                    id="amount"
                                    name="amount"
                                    value={paymentRequest.amount}
                                />
                                <input
                                    type="hidden"
                                    id="currency"
                                    name="currency"
                                    value={paymentRequest.currency}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_forename"
                                    name="bill_to_forename"
                                    value={paymentRequest.bill_to_forename}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_surname"
                                    name="bill_to_surname"
                                    value={paymentRequest.bill_to_surname}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_email"
                                    name="bill_to_email"
                                    value={paymentRequest.bill_to_email}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_address_line1"
                                    name="bill_to_address_line1"
                                    value={paymentRequest.bill_to_address_line1}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_address_line2"
                                    name="bill_to_address_line2"
                                    value={paymentRequest.bill_to_address_line2}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_address_country"
                                    name="bill_to_address_country"
                                    value={paymentRequest.bill_to_address_country}
                                />
                                <input
                                    type="hidden"
                                    id="bill_to_address_city"
                                    name="bill_to_address_city"
                                    value={paymentRequest.bill_to_address_city}
                                />
                                <input
                                    type="hidden"
                                    id="signature"
                                    name="signature"
                                    value={paymentRequest.signature}
                                />
                                <button type="submit" className="btn_register"> Confirm Payment </button>
                            </form>
                        </div>

                    </div>
                }
                {step === 'stage3' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb-wrap reg">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active">Step 3</li>
                                            <li className="breadcrumb-item" aria-current="page"> Payment Success</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="form-wrap-titles">
                            <h1> Success</h1>
                             <p> Your Payment was Successfully Tranfered.</p>
                            </div>
                        </div>
                        <div className="row">
                    
                        </div>
                    </div>
                }
                {step === 'stage4' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb-wrap reg">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active">Step 3</li>
                                            <li className="breadcrumb-item" aria-current="page"> Payment Not Success</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="form-wrap-titles">
                            <h1> Not Success</h1>
                             <p> Your Payment was Not Successfully Tranfered.</p>
                            </div>
                        </div>
                        <div className="row">
                    
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default Payment;
