import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';
import Iframe from 'react-iframe'

const CustomerSupport = () => {
    return (
        <React.Fragment>
            <Header name={{ name: 'Welcome to React', group: 'support' }} />
            <div className="contentswrap" >
                <section className="banner-wrapper cs" style={{ backgroundImage: `url(${"assets/img/customer-support-banner.png"})`, }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="banner-title">
                                    <h1>Customer Support</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cs-contents">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="chat-wrap">
                                    <Iframe url="https://tawk.to/chat/64d2637794cf5d49dc69344f/1h7at9386"
                                        width="100%"
                                        id="myId"
                                        className="myClassname"
                                        height="300px"
                                        display="block"
                                        position="relative"
                                        styles={{ height: "400px" }} />
                                </div>
                                <div className="cs-title">
                                    <h1>Global Trade Manager and Document Preparation Center</h1>
                                    <p>FedEx Global Trade Manager and the Document Preparation Center provide you with a one-stop resource <br />for international shipping information.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </section>
                <section className="additional-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="a-info-title">
                                    <h2>Additional Resources</h2>
                                    <p>Need to ship through customs? Check out all of the resources available to you with just a click of a button.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="a-info-box">
                                    <div className="ad-info-image">
                                        <img src="assets/img/FeBox_White_AWB_Pouch_Lifestyle_061417.jpg" alt="" />
                                    </div>
                                    <a href=""><h3>Duties & Taxes</h3> </a>
                                    <p>When shipping goods internationally, it is important to consider the effects of duties, taxes, and other clearance charges (in addition to the transportation cost) when determining a shipment's total cost (landed cost). </p>
                                    <br></br>
                             
                                    {/* <a href="" className="btn-learnmore">LEARN MORE</a> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="a-info-box">
                                    <div className="ad-info-image">
                                        <img src="assets/img/docx.jpg" alt="" />
                                    </div>
                                    <a href=""><h3>International Shipping Tools</h3> </a>
                                    <p>GTM helps businesses manage and navigate international trade and shipping processes. Work the way you want with our new full-featured document preparation center. You can save the documents you use most so you have them available anytime.</p>
                                    <br></br>
                                   
                                    {/* <a href="" className="btn-learnmore">LEARN MORE</a> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="a-info-box">
                                    <div className="ad-info-image">
                                        <img src="assets/img/fy21_apac_338.jpg" alt="" />
                                    </div>
                                    <a href=""><h3>Document Preparation.</h3> </a>
                                    <p>If you are shipping documents, there are no forms required because you are shipping a written, typed, or printed form of communication with no commercial value.If you are shipping packages, proper documentation is required to prevent unnecessary delays and frustration. </p>
                                    <br></br>
                                   
                                    {/* <a href="" className="btn-learnmore">LEARN MORE</a> */}
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="a-info-box">
                                    <div className="ad-info-image">
                                        <img src="assets/img/ar1.jpg" alt="" />
                                    </div>
                                    <a href=""><h3>Document Preparation Centre</h3> </a>
                                    <p>Work the way you want with our new full featured document preparation center. You can save the documents you use most so you have them available anytime. You can even take a break then pick up where you left off.</p>
                                    <a href="" className="btn-learnmore">LEARN MORE</a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="a-info-box">
                                    <div className="ad-info-image">
                                        <img src="assets/img/ar1.jpg" alt="" />
                                    </div>
                                    <a href=""><h3>Customs clearance for those special shipments</h3> </a>
                                    <p>FedEx offers standard customs clearance, however, we also offer a comprehensive range of value added solutions when your shipment has special clearance needs.</p>
                                    <a href="" className="btn-learnmore">LEARN MORE</a>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="a-info-box">
                                    <div className="ad-info-image">
                                        <img src="assets/img/ar1.jpg" alt="" />
                                    </div>
                                    <a href=""><h3>Customs clearance for those special shipments</h3> </a>
                                    <p>FedEx offers standard customs clearance, however, we also offer a comprehensive range of value added solutions when your shipment has special clearance needs.</p>
                                    <a href="" className="btn-learnmore">LEARN MORE</a>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </section>


            </div>
            <Footer />
        </React.Fragment>
    );
}

export default CustomerSupport;