
const Footer = () => {
   return (
       <footer className="footer-wrap">
  <div className="footer-container">
     <div className="container">
        <div className="row">        
           <div className="col-md-6">
              <div className="footer-col">
                 <h2>OUR COMPANY</h2>
                 <div className="flinks">
                    <a href="/Whychoose-us">Why Choose FedEx</a>
                    {/* <a href="/about-us">About Us</a> */}
                   
                    <a href="https://www.fedex.com/en-us/sustainability.html">Sustainability</a>                                       
                 </div>
                 <div className="flinks">                    
                    <a href="/faq">FAQ</a>  
                    <a href="/contact-us">Contact Us</a>
                    {/* <a href="/career">Careers at FedEx</a>                                                            */}
                 </div>

              </div>
           </div>
           <div className="col-md-3">
              <div className="footer-col-2">
                 <h2>MORE FROM FEDEX</h2>
                 <div className="flinks">
                    <a href="https://newsroom.fedex.com/">Global Newsroom</a>
                    <a href="https://developer.fedex.com/api/en-us/home.html">FedEx Developer Portal</a>
                 </div>
              </div>
           </div>
           <div className="col-md-3">
              <div className="footer-col-3">
                 <h2>PREFERENCES</h2>
                 <div className="flinks">
                    <a href="https://www.facebook.com/share/eXQepF8jgsbwjHnA/?mibextid=qi2Omg" target="_blank"><img src="assets/img/facebook-icon.svg" alt=""/></a>
                    <a href="https://www.linkedin.com/company/81911039/admin/feed/posts/" target="_blank"><img src="assets/img/icons8-linkedin.svg" alt=""/></a>
                    <a href="https://www.instagram.com/advantisexpress/" target="_blank"><img src="assets/img/instagram-icon8.svg" alt=""/></a>
                 </div>
              </div>
           </div>
        </div>      
     </div>
     <div className="copyrights-wrap">
        <div className="container">
           <div className="row">
              <div className="col-md-6">
                 <div className="copyrights">
                    <p>© 2023 Advatis Express. All Rights Reserved. </p>                    
                 </div>                
              </div>
              <div className="col-md-6">
                 <div className="fbootom-links">
                    {/* <p><a href="">Terms of Use</a> |  */}
                  <p>  <a href="security-privacy">Security and Privacy</a></p>
                 </div>                
              </div>
           </div>
        </div>
     </div>


  </div>
</footer>
   );
}

export default Footer;