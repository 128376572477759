import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const CareersDetails = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
            <div className="container">       
                <div className="row">
                <div className="col-md-8">
                    <div className="career-detail-wrap">
                        <div className="backlink-wrap">
                            <a href="" className="all-topics">BACK TO JOB LISTING</a>
                        </div>
                        <div className="career-contents">
                            <h2>REF - EX21004OK</h2>
                            <h1>Deputy Head – Regional 01 Sales</h1>
                            <p>Would you like to become part of the world’s most international company? A company that pioneered cross-border express delivery in 1969 and it now active in more than 220 countries and territories worldwide. Do you want to be part of a company that connects people worldwide? And the more people we connect, the better life will be on our planet. Do you want to make a difference? Then come to our Insanely Centric Team and become a Certified International Specialist!</p>
                            <div className="subsections">
                                <h3>Your tasks</h3>
                                <ul>
                                    <li>Develop new business and grow revenue by identifying potential new customers, conducting background research</li>
                                    <li>on potential customers, discussing the customer’s supply chain with decision makers and other appropriate customer</li>
                                    <li>representatives, analyzing customer needs, and developing and implementing solutions</li>
                                    <li>Maintain existing business by developing an understanding of the customer’s business and supply chain through</li>
                                    <li>Research and customer interaction, analyzing customer needs, and developing and implementing solutions.</li>
                                    <li>Coordinate and collaborate with internal teams, such as sales counterparts as well as other internal partners to</li>
                                    <li>provide a seamless and outstanding customer experience.</li>
                                    <li>Engage regularly with customers to ensure customer understanding of FedEx services and to maximize customer.</li>
                                    <li>Utilization of FedEx services</li>
                                    <li>Foster an outstanding customer relationship by providing regular business reviews, entertaining customers,</li>
                                    <li>Developing solutions, exhibiting outstanding communication skills, both oral and written, and learning the customer’s</li>
                                    <li>Leverage new and existing technology tools such as internal systems, mobile devices, and PowerPoint. · Demonstrate initiative, organization, discipline, and adaptability while working in a team environment.</li>
                                </ul>
                            </div>
                            <div className="subsections">
                                <h3>MINIMUM KNOWLEDGE/ EXPERIENCE / TRAINING / QUALIFICATIONS REQUIRED FOR THE POSITION</h3>
                                <ul>
                                    <li>Demonstrate initiative, organization, discipline, and adaptability while working in a team environment</li>
                                    <li>Bachelor's Degree approved by UGC or Equivalent professional qualification with a minimum of 3 years of experience</li>
                                </ul>
                            </div>
                            <div className="subsections">
                                <h3>preferably in the Transportation & Logistics industry.</h3>
                                <ul>
                                    <li>Any professional qualification in business would be an advantage.</li>
                                    <li>Computer literacy in MS Office packages</li>
                                    <li>Ability to work independently.</li>   
                                    <li>Confident communication and the ability to manage multiple task.</li>   
                                </ul>
                            </div>
                            <div className="subsections">
                                <h3>Our Location</h3>
                                <div className="location-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7920.680723968361!2d79.9533411803955!3d6.969113297413837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae257cf466b9ded%3A0x1eb61e8197627cb5!2sHayleys%20Advantis%20Limited%20Stores!5e0!3m2!1sen!2slk!4v1678677283662!5m2!1sen!2slk" width="100%" height="340" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                
                                </div>                           
                            </div>
                            <div className="subsections sub text-center">
                                <h3 className="">We request all applicants to ensure that the above-mentioned requirements are met prior to applying to the post</h3>
                                <div className="apply-wrap">
                                    <a href="/career" className="btn-apply">Apply Positions</a>                            
                                </div>                           
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="career-quick-info">
                        <div className="carrer-row">
                            <h4>Location</h4>
                            <h5><span><img src="assets/img/location-icon.jpg" alt=""></img></span> Nawam Mawatha</h5>
                        </div>
                        <div className="carrer-row">
                            <h4>Basis</h4>
                            <h5>Full-time</h5>
                        </div>
                        <div className="carrer-row">
                            <h4>Valid until</h4>
                            <h5>10/10/2023</h5>
                        </div>
                        <div className="offer-wrap">
                            <h6>Our Offer</h6>
                            <ul>
                                <li>Strong career support in an international environment</li>
                                <li>Great culture and colleagues</li>
                                <li>Multifarious benefit program</li>
                            </ul>
                            <a href="" className="btn-apply">Apply Positions</a> 
                            <div className="share-wrap">
                                <a href="/career"><h5><span> <img src="assets/img/icon-share.png" alt=""></img> </span>Share with friends</h5></a>
                            </div>
                        </div>                 
                    </div>
                </div>
                </div>
            </div>
        </div>
        
       
      
          <Footer/>
    </React.Fragment>
    );
  }
  
  export default CareersDetails;