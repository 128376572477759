import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const whychoose = () => {
    return (
      <React.Fragment>
        <Header name={{ name: "Advantis Express", group: "home" }} />
        <div className="contentswrap">
          <section
            className="banner-wrapper"
            style={{
              backgroundImage: `url(${"assets/img/aboutus-banner.png"})`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="banner-title">
                    <h1>Why Choose Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap bann">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">HOME</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        WHY CHOOSE US
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-titles about" data-aos="fade-down">
                  <h1>
                    Why Ship with FedEx
                    <span className="rmark">
                      <sup>®</sup>
                    </span>
                  </h1>
                  <p>
                    Ship with confidence–no matter what. We’ve provided
                    dependable shipping solutions for businesses of all sizes
                    for the past 50 years. We offer advanced tools that empower
                    you to generate detailed reports, explore information about
                    duties and taxes for special shipments, and more – all
                    designed for ease of use. We proudly deliver to over 220
                    countries and territories worldwide, with Customer Service
                    centers spanning the globe.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="whychoose">
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-7">
                  <div className="whychoose-image" data-aos="fade-down">
                    <img src="assets/img/w1.png" alt=""></img>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="whychoose-contents" data-aos="fade-down">
                    <div className="whychoose-icon">
                      <img src="assets/img/wicon1.png" alt=""></img>
                    </div>
                    <h2>
                      Connecting customers <br></br> for over 50 years
                    </h2>
                    <p>
                      Whether you're a small business owner with evolving
                      shipping requirements or part of a global corporation
                      managing a complex supply chain, we have the knowledge and
                      expertise to support your growth and success, regardless
                      of your business size.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-5">
                  <div className="whychoose-contents" data-aos="fade-down">
                    <div className="whychoose-icon">
                      <img src="assets/img/wicon2.png" alt=""></img>
                    </div>
                    <h2>A Commitment to Sustainability</h2>
                    <p>
                      We're committed to reaching carbon-neutral operations by
                      2040, and we invite everyone to join us in this endeavor.
                      Whether you're seeking sustainable practices for your home
                      or workplace, we're here to assist. Explore ideas ranging
                      from waste reduction to adopting eco-friendly packaging,
                      and discover ways to simultaneously enhance sustainability
                      and cut costs.
                    </p>
                    {/* <a
                      href="https://www.fedex.com/en-us/why-fedex-for-business.html#acommitmenttosustainability"
                      className="learnmore"
                    >
                      Read more
                    </a> */}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="whychoose-image" data-aos="fade-down">
                    <img src="assets/img/w2.png" alt=""></img>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-7">
                  <div className="whychoose-image" data-aos="fade-down">
                    <img src="assets/img/w3.png" alt=""></img>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="whychoose-contents" data-aos="fade-down">
                    <div className="whychoose-icon">
                      <img src="assets/img/wicon3.png" alt=""></img>
                    </div>
                    <h2>Customer Service and Support</h2>
                    <p>
                      We earned our reputation by taking care of our customers
                      for 50 years. Rely on our experts available 24/7 to answer
                      your questions, give you recommendations, discuss
                      discounts, and more.
                    </p>
                    {/* <a href="/customer-support" className="learnmore">
                      Connect with an expert
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-5">
                  <div className="whychoose-contents" data-aos="fade-down">
                    <div className="whychoose-icon">
                      <img src="assets/img/wicon2.png" alt=""></img>
                    </div>
                    <h2>Nowhere is Closer Than Home</h2>
                    <p>
                      Don’t want to drop off your package at a FedEx location?
                      Let us come to your home or business. Save time by
                      scheduling one-time, automatic, or recurring pick-ups.
                    </p>
                    {/* <a
                      href="https://www.fedex.com/en-us/shipping/schedule-manage-pickups.html"
                      className="learnmore"
                    >
                      SEE PICKUP SOLUTIONS
                    </a> */}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="whychoose-image" data-aos="fade-down">
                    <img src="assets/img/fedex-about.png" alt=""></img>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-7">
                  <div className="whychoose-image" data-aos="fade-down">
                    <img src="assets/img/fedex-about2.png" alt=""></img>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="whychoose-contents" data-aos="fade-down">
                    <div className="whychoose-icon">
                      <img src="assets/img/wicon3.png" alt=""></img>
                    </div>
                    <h2>Achieve E-commerce Goals</h2>
                    <p>
                      Whether you're starting out with your business or managing
                      multiple orders, we can help make e-commerce easier. Get
                      ahead with our tools and tips.
                    </p>
                    <a
                      href="https://www.fedex.com/en-us/small-business/ecommerce.html"
                      className="learnmore"
                    >
                      GROW AND SCALE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="additional">
            <div className="container">
               <div className="row">
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-1.png" alt=""></img>   
                        </div>
                        <h4>Business & <br></br>E-Commerce</h4>
                        <a href="" className="btn-border">Grow your business</a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-2.png" alt=""></img>   
                        </div>
                        <h4>Ship & Save up to 45% <br></br>for 6 months</h4>
                        <a href="" className="btn-border">Open an account</a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-3.png" alt=""></img>   
                        </div>
                        <h4>International Shipping<br></br> Expertise</h4>
                        <a href="" className="btn-border">Find out more</a>
                     </div>
                  </div>
                  <div className="col-md-12" data-aos="fade-down">
                     <p className="term">*Some restrictions apply. See the Terms and Conditions section of the FedEx Service Guide and visit fedex.ca for details.</p>
                  </div>
               </div>
            </div>
         </section> */}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
  
  export default whychoose;
  