import React from "react";
import Sidebar from "../../components/CustomerPortal/SideBar";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
const PaymentMethod = () => {
  return (
    <div>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div class="p-4 sm:ml-64">
        <div class="p-4 rounded-lg  ">
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div class="text-xs text-gray-700  dark:text-gray-400">
              <ol class="flex items-center w-full p-3 justify-center space-x-8 text-sm font-medium text-center gap-20 text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                <li class="flex items-center">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    1
                  </span>
                  Select Payment
                  <span class="hidden sm:inline-flex sm:ms-2"></span>
                  <svg
                    class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li class="flex items-center text-blue-600 dark:text-blue-500">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                    2
                  </span>
                  Payment Method
                  <span class="hidden sm:inline-flex sm:ms-2"></span>
                  <svg
                    class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m7 9 4-4-4-4M1 9l4-4-4-4"
                    />
                  </svg>
                </li>
                <li class="flex items-center">
                  <span class="flex items-center justify-center w-5 h-5 me-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    3
                  </span>
                  Payment Confirmation
                </li>
              </ol>
            </div>
          </div>
          <div class="">
            <h2 className="text-2xl pt-4 pl-4 md:pl-12 md:pr-8 flex justify-center pb-10">
              PAYMENT METHOD
            </h2>
          </div>
          <div class="grid justify-items-center">
            <div class="grid gap-2 pt-5 mt-1">
              <div class="flex items-center">
                <input
                  type="radio"
                  class="w-5 h-5 cursor-pointer"
                  id="card"
                  checked
                />
                <label for="card" class="ml-4 flex gap-2 cursor-pointer">
                  <img
                    src="https://readymadeui.com/images/visa.webp"
                    class="w-12"
                    alt="card1"
                  />

                  <img
                    src="https://readymadeui.com/images/master.webp"
                    class="w-12"
                    alt="card3"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="pt-10 flex justify-between">
            <a href="/payment-cart">
              <button
                type="button"
                class="text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-2.5 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
              >
                <div className="flex flex-nowrap ">
                  <div className="flex items-center pr-2">
                    <svg
                      class="w-3 h-3 ms-2 sm:ms-4 transform rotate-180 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  </div>
                  BACK
                </div>
              </button>
            </a>
            <a href="/payment-confirm">
              <button
                type="button"
                class="text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-2.5 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
              >
                <div className="flex flex-nowrap ">
                  NEXT
                  <div className="flex items-center">
                    <svg
                      class="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180 "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 12 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m7 9 4-4-4-4M1 9l4-4-4-4"
                      />
                    </svg>
                  </div>
                </div>
              </button>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentMethod;
