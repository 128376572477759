import React, { useState, useEffect } from "react";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
import Sidebar from "../../components/CustomerPortal/SideBar";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import {
  getFirstLastNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import { API_ROUTES } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import DataTable from "react-data-table-component";

const InvoiceDetailView = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const invoiceNumber = queryParams.get("invoice-number") || "";
  const [invoiceData, setInvoiceData] = useState(null);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState("");
 const tableCustomStyles = {
   headRow: {
     style: {
       color: "#13324E",
       backgroundColor: "#e7eef0",
       textAlign: "center",
     },
   },
   rows: {
     style: {
       textAlign: "center",
       fontSize: "14px",
     },
     stripedStyle: {
       textAlign: "center",
     },
   },
   headCells: {
     style: {
       justifyContent: "center",
       fontSize: "14px",
       fontWeight: "bold",
     },
   },
   cells: {
     style: {
       justifyContent: "center",
     },
   },
 };
  useEffect(() => {
    setUserName(getFirstLastNameFromLocalStorage());

    let config = {
      Authorization: getTokenFromLocalStorage(),
    };

    axios({
      method: "post",
      url: API_ROUTES.INVOICE_DETAILS,
      headers: config,
      data: {
        "invoice-number": invoiceNumber,
      },
    })
      .then((response) => {
        if (response.data.data) {
          setInvoiceData(response.data.data);
        } else {
          setError("Invoice not found");
        }
      })
      .catch((error) => {
        setError("An error occurred while fetching invoice details");
        console.error("Error:", error);
      });
  }, [invoiceNumber]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!invoiceData) {
    return (
      <div class="flex justify-center items-center">
        <Bars
          height="100vh"
          width="80"
          color="#4d148c"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  const billingInfo = invoiceData.BillingInformation || {};
const columns = [
  {
    name: "Charge Description",
    selector: (row) => row.ChargeDesc,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div style={{ textAlign: "right" }}>
        <NumericFormat
          value={row["Amount LKR"]}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </div>
    ),
    sortable: false,
    right: true,

    style: {
      justifyContent: "flex-end",
    },
  },
  {
    name: "Tax",
    selector: (row) => (
      <div style={{ textAlign: "right" }}>
        <NumericFormat
          value={row["Tax LKR"]}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </div>
    ),
    sortable: false,
    right: true,

    style: {
      justifyContent: "flex-end",
    },
  },
  {
    name: "Total",
    selector: (row) => (
      <div style={{ textAlign: "right" }}>
        <NumericFormat
          value={row["Amount LKR"] + row["Tax LKR"]}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </div>
    ),
    sortable: false,
    right: true,

    style: {
      justifyContent: "flex-end",
    },
  },
];
  return (
    <div style={{ maxWidth: "1536px", margin: "0 auto" }}>
      <div>
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div className="p-2 mt-4 sm:ml-64">
        <div class="p-8 sm:p-10 overflow-y-auto ">
          <div class="text-center">
            <h3 className="text-4xl text-blue pt-14 pb-1 md:pl-20 md:pr-20 font-bold flex justify-center  ">
              Invoice from FedEx
            </h3>
            <p class="text-xl text-green-500 dark:text-neutral-500">
              Invoice : {billingInfo["Invoice No"]}
            </p>
          </div>
          <div class="mt-2 sm:mt-10 grid text-center  grid-cols-2 sm:grid-cols-3 gap-5 bg-slate-100  shadow dark:bg-gray-800 ">
            <div className="m-3">
              <span class="text-xs font-semibold uppercase text-gray-800 dark:text-neutral-200 text-blue">
                Customer Code :
              </span>
              <span class="block text-sm font-medium text-gray-800 dark:text-neutral-200 text-blue">
                <p>{billingInfo["Account No"]}</p>
              </span>
            </div>
            <div className="m-3">
              <span class="text-xs font-semibold uppercase text-gray-800 dark:text-neutral-200 text-blue">
                Date :
              </span>
              <span class="block text-sm font-medium text-gray-800 dark:text-neutral-200 text-blue">
                <p>
                  {new Date(billingInfo["Invoice Date"]).toLocaleDateString(
                    "en-UK",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  )}
                </p>
              </span>
            </div>
            <div className="m-3">
              <span class="text-xs font-semibold uppercase text-gray-800 dark:text-neutral-200 text-blue">
                invoice to :
              </span>

              <span class="block text-sm font-medium text-gray-800 dark:text-neutral-200 text-blue">
                <p>
                  {`
                          ${userName}`}
                </p>
              </span>
            </div>
          </div>
          <div class="mt-5 sm:mt-10">
            <h4 class="text-xs font-semibold uppercase text-gray-800 dark:text-neutral-200 pb-2 text-blue">
              Summary
            </h4>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg text-xl">
              <DataTable
                columns={columns}
                data={invoiceData.ChargeSummary}
                noHeader
                highlightOnHover
                customStyles={tableCustomStyles}
              />
            </div>

            <div className="mt-4 pt-4 md:pt-4 pr-5 justify-end grid ">
              <table className="text-m text-blue">
                <tbody>
                  <tr>
                    <td className=""> Total Payments</td>
                    <td>
                      <NumericFormat
                        value={invoiceData.TotalPaymentsLKR}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className="grid place-content-end pl-10"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="flex text-gray-500 capitalize text-blue text-s pt-2 font-semibold">
                      Total Balance
                    </td>
                    <td className="text-s mt-2 text-blue pt-2 font-semibold">
                      <NumericFormat
                        value={invoiceData.TotalBalanceDueLKR}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className="grid place-content-end pl-10"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p class="text-sm  leading-6 text-gray-900  pt-3 font-bold">
            The invoice details provided are for viewing purposes only and do
            not constitute a binding agreement or official document.
          </p>

          {/* <div class="mt-5 flex justify-end gap-x-2">
            <a
              className="text-purple-600 inline-flex justify-center items-center hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-1 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
              href="#"
            >
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" x2="12" y1="15" y2="3" />
              </svg>
              Invoice PDF
            </a>
            <a
              className="text-purple-600 inline-flex justify-center items-center hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-1 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 "
              href="#"
            >
              <svg
                class="flex-shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <polyline points="6 9 6 2 18 2 18 9" />
                <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                <rect width="12" height="8" x="6" y="14" />
              </svg>
              Print
            </a>
          </div> */}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default InvoiceDetailView;
