import Header from "../components/Header";
import Footer from "../components/Footer";
import React from "react";

const Agreement = () => {
  return (
    <React.Fragment>
      <Header name={{ name: "Advantis Express", group: "home" }} />
      <div className="contentswrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-wrap faq2"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="faq-content-col">
                <div className="faq-search-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <div class="container mx-auto mt-10 p-6 bg-white rounded shadow-lg">
                        <h1 class="text-3xl font-bold text-center mb-6">
                          Customer Registration Agreement
                        </h1>
                        <p class="mb-4">
                          By registering an account with Advantis Express
                          ("Company", "we", "us", "our"), you ("Customer",
                          "you", "your") agree to comply with and be bound by
                          the following terms and conditions. This agreement
                          governs your use of our services and your relationship
                          with Advantis Express. If you do not agree to these
                          terms, you should not register an account or use our
                          services.
                        </p>

                        <h2 class="text-2xl font-semibold mb-3">
                          Account Creation
                        </h2>
                        <ul class="list-disc list-inside mb-4">
                          <li>
                            You must be at least 18 years old to create an
                            account with Advantis Express.
                          </li>
                          <li>
                            You agree to provide accurate, current, and complete
                            information during the registration process and to
                            update such information to keep it accurate,
                            current, and complete.
                          </li>
                          <li>
                            You are responsible for maintaining the
                            confidentiality of your account login information
                            and for all activities that occur under your
                            account.
                          </li>
                          <li>
                            You agree to notify us immediately of any
                            unauthorized use of your account or any other breach
                            of security.
                          </li>
                        </ul>

                        <h2 class="text-2xl font-semibold mb-3">
                          Use of Services
                        </h2>
                        <p class="mb-4">
                          You agree to use our services only for lawful purposes
                          and in accordance with all applicable laws and
                          regulations.
                        </p>
                        <p class="mb-4">
                          You agree not to use our services to:
                        </p>
                        <ul class="list-disc list-inside mb-4">
                          <li>
                            Post or transmit any content that is unlawful,
                            harmful, threatening, abusive, harassing,
                            defamatory, vulgar, obscene, or otherwise
                            objectionable.
                          </li>
                          <li>
                            Violate the privacy rights of others or collect or
                            store personal data about other users without their
                            consent.
                          </li>
                          <li>
                            Impersonate any person or entity, or falsely state
                            or otherwise misrepresent your affiliation with a
                            person or entity.
                          </li>
                          <li>
                            Interfere with or disrupt the operation of our
                            services or servers or networks connected to our
                            services.
                          </li>
                        </ul>

                        <h2 class="text-2xl font-semibold mb-3">
                          Service Modifications
                        </h2>
                        <p class="mb-4">
                          Advantis Express reserves the right to modify,
                          suspend, or discontinue, temporarily or permanently,
                          the services (or any part thereof) with or without
                          notice at any time. You agree that Advantis Express
                          shall not be liable to you or to any third party for
                          any modification, suspension, or discontinuance of the
                          services.
                        </p>

                        <h2 class="text-2xl font-semibold mb-3">Termination</h2>
                        <p>
                          Advantis Express may, in its sole discretion,
                          terminate your account or use of the services and
                          remove and discard any content within the services if
                          we believe that you have violated or acted
                          inconsistently with the letter or spirit of this
                          agreement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Agreement;
