import LoginForm from './LoginForm';
import { useState, useEffect } from 'react';
import {  verifyAuth,  verifyPortal, getFirstLastNameFromLocalStorage } from '../lib/common';


const Header = ({ name }) => {
     const grop = name.group;
     const [user2, setUser] = useState(null);
     const [portal, setPortal] = useState(false);
     console.log(user2);
     useEffect(() => {
      async function GetToken() {
         const auth = await verifyAuth();
         if (auth) {
            setUser(getFirstLastNameFromLocalStorage());
            var  portal = await verifyPortal();
            console.log("***********");
            console.log(portal);
            setPortal(portal)

         }
      }
      GetToken();
   }, []);
   


    return (
      <header className="header-wrap">
        <div className="header-innwrap">
       <div className="container">
          <div className="row">
          <div className="container-fluid">
          <div className="col-lg-12">
                <div className="menuwrap">
                   <a href="/" className="logo"><img src="/assets/img/logo.png" alt=""/></a>
                   <div className="menu">
                     {/* <!-- Main Menu --> */}
                     <nav className="navbar navbar-expand-lg navbar-light mainnav">
                        <div className="container-fluid">
                           <button className="navbar-toggler first-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                              <div className="animated-icon1"><span></span><span></span><span></span></div>
                           </button>  
                           <div className=" navbar-collapse" id="navbarNavDropdown">
                              <ul className="navbar-nav">
                                 <li className="nav-item">
                                    <a className={ grop === 'home' ? ' nav-link active' : 'nav-link'} aria-current="page" href="/"> Home</a>
                                 </li>
                                 <li className="nav-item">
                                    <a className={ grop === 'payment' ? ' nav-link active' : 'nav-link'} aria-current="page" href="payments"> Payment</a>
                                 </li>
                                 <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Shipping</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li><a className="dropdown-item" href="https://www.fedex.com/shippingplus/en-lk/shipment/create">Ship Now</a></li>
                                       <li><a className="dropdown-item" href="register">Open a FedEx Account</a></li>
                                       <li><a className="dropdown-item" href="shipping-rates">Shipping Rates & Delivery Times</a></li>
                                       {/* <li><a className="dropdown-item" href="schedule-pickup">Schedule & Manage Pick-ups</a></li> */}
                                       {/* <li><a className="dropdown-item" href="/order-details">Order Supplies</a></li> */}
 
                                    </ul>
                                 </li>
                                 <li className="nav-item dropdown">
                                    <a  className={ grop === 'track' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Tracking</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                       <li><a className="dropdown-item" href="track">Tracking </a></li>
                                    </ul>
                                 </li>
                                 <li className="nav-item dropdown">
                                    <a className={ grop === 'support' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Support</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                       <li><a className="dropdown-item" href="customer-support">Customer Support</a></li>
                                       <li><a className="dropdown-item" href="contact-us">Contact Us</a></li>
                                    </ul>
                                 </li>
                                 {(user2 !== null && portal === true ) && ( 
                                 <li className="nav-item">
                                    <a className='nav-link' aria-current="page" href="dashboard">  Customer Portal  </a>
                                 </li>
                                 )}
                              </ul>
                           </div>
                        </div>
                     </nav>
                     <LoginForm />
                  </div>
                </div>
             </div>
          </div>
          </div></div>
          </div>
          </header>        
      );
}

export default Header;