import Header from "../components/Header";
import Footer from "../components/Footer";
import { useForm } from 'react-hook-form';
import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { API_ROUTES, APP_ROUTES } from '../utils/constants';
import axios from 'axios';
// import Moment from 'moment';
import Moment from 'moment-timezone'
import { useLocation } from "react-router-dom";


const Track = () => {

   const { search } = useLocation();
   const queryParams = new URLSearchParams(search);
   const initialTrackingNumber = queryParams.get("track_id") || "";
    
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();
    
    useEffect(() => {
    setValue("track_id", initialTrackingNumber);
    handleSubmit(onSubmit)({ track_id: initialTrackingNumber });
  }, [initialTrackingNumber, setValue, handleSubmit]);

    const [track, setTrack] = useState([]);
    const [step, setStep] = useState("stage1");
    const [showMore, setShowMore] = useState(false);
const onSubmit = async (form_data) => {
        const { data } = await axios({
            method: 'get',
            url: API_ROUTES.TRACK +form_data.track_id
        });
       

        console.log(data?.meta?.status);
        if (data.meta?.status) {
            setTrack(data.data);
            console.log(data?.data);
            setStep("stage2");
        }
    }

    const more = async () => {
            setShowMore(true);
    }


    
    
    return (
        <React.Fragment>
            <Header name={{ name: 'Welcome to React' }} />
            <div class="contentswrap">
                <div class="container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-wrap-titles shipping">
                                    <h1>Track a Shipment</h1>
                                </div>
                                <div class="form-wrap shipping">
                                    <div class="row">
                                        <div class="form-floating mb-3 col-md-12" >
                                            <input type="text" class="form-control" {...register('track_id', {
                                                required: "Tracking Number is required",
                                            })} id="" placeholder="Register Form" />

                                            {errors.track?.message && (
                                                <small class="text-danger"  >{errors.track.message}</small>
                                            )}
                                            <label for="floatingInput"> Tracking Number </label>
                                        </div>
                                         <div class="col-12 h-[5rem]">
                                            <button type="submit" class="btn_register" id="show_transit"> Submit </button>
                                        </div>
                                    </div>
                                    <div class="row">
                                       
                                    </div>
                                    {step === "stage2" &&
                                        <div class="row">
                                           <div class="col-md-12 content-center">


                                           { track?.StatusDescription === 'Delivered' ? (
                  <>
                     
    
                     <h2 class="text-center text-3xl">
  <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
  <span class="text-green-500">{ track?.StatusDescription }</span>
</h2>
<div class="flex min-w-0 gap-x-4">
      <div class="min-w-0 flex-auto text-center">
        <p class="text-sm font-semibold leading-6 text-gray-900 text-4xl pt-2">  {Moment.tz(track?.ActualDeliveryTimestamp, 'Asia/Colombo').format('dddd')} </p>
        <p class="mt-1 truncate   leading-5 text-gray-500 text-1xl pt-2">  {Moment.tz(track?.ActualDeliveryTimestamp, 'Asia/Colombo').format('YYYY-MM-DD : h:mm a')} </p>
      </div>
    </div>
                </>
            ) : ( 
            <>
            <h2 class="text-center text-3xl">
  <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
  <span class="text-yellow-500">    Estimated Delivery </span>
</h2>
<div class="flex min-w-0 gap-x-4">
      <div class="min-w-0 flex-auto text-center">
        <p class="text-sm font-semibold leading-6 text-gray-900 text-4xl pt-2">    {Moment.tz(track?.EstimatedDeliveryTimestamp, 'Asia/Colombo').format('dddd')}  </p>
        <p class="mt-1 truncate   leading-5 text-gray-500 text-1xl pt-2">   {Moment.tz(track?.EstimatedDeliveryTimestamp, 'Asia/Colombo').format('YYYY-MM-DD : h:mm a')} </p>
      </div>
    </div>

            </>
            )
          }


{track?.PackageCount && (
                <h2 class="mt-1 truncate text-center leading-5 text-slate-500 text-1xl pt-1"> Total Pieces : {track?.PackageCount}  </h2>
              )}

              {track?.Packaging && (
                <h2 class="mt-1 truncate  text-center  leading-5 text-slate-500 text-1xl pt-1"> Packaging : {track?.Packaging} </h2>
              )}

              {track?.ServiceInfo && (
                <h2 class="mt-1 truncate  text-center  leading-5 text-slate-500 text-1xl pt-1"  > Service : {track?.ServiceInfo} </h2>
              )}


<ul role="list" class="-mb-8">
 
{track.Events.map((item, index) => (
                    <>
                     <li>
                        
      <div class="relative pb-8">
        <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div class="relative flex space-x-3">
            
          
          <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
            <div>
              <h1 class="text-gray-500 text-2xl text-slate-700"> {item?.EventDescription}  </h1>
              <p> {item?.Address?.City}  {item?.Address?.PostalCode} </p>
            </div>
            <div class="whitespace-nowrap text-right text-slate-500">
              <time datetime="2020-09-22"> {Moment.tz(item?.Timestamp, 'Asia/Colombo').format('YYYY-MM-DD : h:mm a')}  </time>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
    </li>
                      {/* <Card elevate size="$2">
                        <Card.Header padded>
                          <H4> {item?.EventDescription} </H4>
                          <Paragraph theme="alt2"> {item?.Address?.City}  {item?.Address?.PostalCode}
                          </Paragraph>
                          <Button borderRadius="$10"> {Moment.tz(item?.Timestamp, 'Asia/Colombo').format('YYYY-MM-DD : h:mm a')}  </Button>
                        </Card.Header>
                      </Card>
                      <Separator></Separator> */}
                    </>
                  ))}

                  </ul>
 
                                            </div>


                                        </div>
                                    }


                                    {/* {track.map(item => {
          return <li>{item}</li>;
        })} */}

                                </div>

                            </div>
                        </div>
                    </form>





                </div>


            </div>
            <Footer />
        </React.Fragment>
    );
}

export default Track;