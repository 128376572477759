import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useRef } from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_ROUTES, APP_ROUTES } from '../utils/constants';
import { storeTokenInLocalStorage } from '../lib/common';
import { useForm } from 'react-hook-form';
import { useUser } from '../lib/customHooks';
//import { reactCodeInput } from 'CodeInputField.scss'
import ReactCodeInput from "react-code-input";

const Register = () => {

    const [apiError, setApiError] = useState(null);
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const [auth_code, setPinCode] = useState("");
    const [step, setStep] = useState("stage1");
    const [phone, setPhone] = useState(null);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const onSubmit = async (form_data) => {
        try {
            const { data } = await axios({
                method: 'post',
                url: API_ROUTES.REGISTER,
                data: form_data
            });
            console.log(data.meta.status);

            if (!data?.meta?.status) {
                console.log("stage 1 error");
                setApiError(data?.meta?.status_message);
            } else {
                setPhone(form_data.phone);
                console.log(form_data.phone);
                setStep("stage2");
            }


        }
        catch (err) {
            setApiError('Some error occured during registration');
            //  console.log('Some error occured during signing in: ', err);
        }
    };

    const onSubmitVeify = async (form_data) => {
        console.log(form_data);
        setIsPinCodeValid(false);
    }


    const verifySms = async () => {
        try {
            const { data } = await axios({
                method: 'post',
                url: API_ROUTES.VERIFY,
                data: {
                    phone,
                    auth_code
                }
            });
            console.log(data.meta.status);

            if (!data?.meta?.status) {
                console.log("stage 1 error");

                setApiError(data?.meta?.status_message);
            } else {
                console.log("stage 2");
                setStep("stage3");
            }


        }
        catch (err) {
            setApiError('Some error occured during registration');
            //  console.log('Some error occured during signing in: ', err);
        }
    };

    const props = {
        inputStyle: {
            fontSize: '32px',
            width: '38px',
            height: '34px',
            fontFamily: 'monospace',
            margin: '4px',
            paddingLeft: '7px',
        },
    }

    const password = useRef({});
    password.current = watch("password", "");

    const handlePinChange = auth_code => {
        setPinCode(auth_code);
        //setBtnIsPressed(false);
    };

    return (
        <React.Fragment>
            <Header name={{ name: 'Welcome to React', group: 'shipping' }} />
            <div className="contentswrap">

                {step === 'stage1' &&
                    <div className="container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="breadcrumb-wrap reg">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item active">Step 1</li>
                                                <li className="breadcrumb-item" aria-current="page">Personal Information</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-wrap-titles">
                                        <h1>Welcome to FedEx. Let’s get started.</h1>
                                        <p>Enter your contact information, or log in, if you already have a user ID and password.</p>
                                    </div>

                                    <div className="form-wrap register">

                                        {apiError && <div sh={apiError} className="alert alert-danger" role="alert">
                                            {apiError}
                                        </div>
                                        }



                                        <div className="row g-3">
                                            <div className="form-floating mb-3 col-md-6" >
                                                <input type="text" className="form-control" {...register('first_name', {
                                                    required: "First Name is required",
                                                })} id="" placeholder="Register Form" />
                                                {/* errors will return when field validation fails  */}
                                                {errors.first_name?.message && (
                                                    <small className="text-danger"  >{errors.first_name.message}</small>
                                                )}
                                                <label for="floatingInput">First Name</label>
                                            </div>
                                            <div className="form-floating mb-3 col-md-6">
                                                <input type="text" className="form-control" {...register('last_name', {
                                                    required: "Last Name is required",
                                                })} placeholder="Register Form" />
                                                {/* errors will return when field validation fails  */}
                                                {errors.last_name?.message && (
                                                    <small className="text-danger"  >  {errors.last_name.message}</small>
                                                )}
                                                <label for="floatingInput">Last Name</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="text" className="form-control" id="company_name" placeholder="Register Form"  {...register('company_name')} />
                                                {errors.company_name?.message && (
                                                    <small className="text-danger"  >{errors.company_name.message}</small>
                                                )}
                                                <label for="floatingInput">Company</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="phone" className="form-control" id="phone"  {...register('phone', {
                                                    required: "Phone is required", pattern: {
                                                        value: /^\d{10}$/,
                                                        message:
                                                            "Phone Number must be 10 digit"
                                                    }
                                                })} placeholder="Register Form" />
                                                {errors.phone?.message && (
                                                    <small className="text-danger"  >{errors.phone.message}</small>
                                                )}
                                                <label for="floatingInput">Phone</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="email" className="form-control" id="email" {...register('email', {
                                                    required: "Email is required",
                                                    validate: {
                                                        matchPattern: (v) =>
                                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                            "Email address must be a valid address",
                                                    },
                                                })} placeholder="Register Form" />
                                                {errors.email?.message && (
                                                    <small className="text-danger" >{errors.email.message}</small>
                                                )}
                                                <label for="floatingInput">Email Address</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="password" className="form-control" id="password" {...register('password', { required: "Password is required" })} placeholder="Register Form" />
                                                {errors.password?.message && (
                                                    <small className="text-danger" >{errors.password.message}</small>
                                                )}
                                                <label for="floatingInput"> Password</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-floating mb-3 col-md-12" >
                                                <input type="password" className="form-control" id="confirm_password" {...register('confirm_password', {
                                                    required: "Password is required", validate: value =>
                                                        value === password.current || "The passwords do not match"
                                                })} placeholder="Register Form" />
                                                {errors.confirm_password?.message && (
                                                    <small className="text-danger" >{errors.confirm_password.message}</small>
                                                )}
                                                <label for="floatingInput"> Confirm Password</label>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="form-check mb-3 col-md-12 text-center">
                                           
                                                  <label className="form-check-label" htmlFor="terms">
                                                  <input
                                                    className="form-check-input text-center"
                                                    type="checkbox"
                                                    id="terms"
                                                    {...register('terms', { required: "You must agree to the terms and conditions" })}
                                                />
                                              
                                              I agree to the    <a href="/agreement" target="_blank" className="loginnow">  terms and conditions </a>
                                                {errors.terms?.message && (
                                                    <p>
                                                    <small className="text-danger">{errors.terms.message}</small>
                                                    </p>
                                                )}    
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <button type="submit" className="btn_register">ENTER LOGIN DETAILS</button>
                                                <span className="memberlink">Already a member?, <a href="/login" className="loginnow">Login now</a></span>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                }

                {step === 'stage2' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb-wrap reg">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active">Step 2</li>
                                            <li className="breadcrumb-item" aria-current="page"> Sms Verification</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="form-wrap-titles">
                                <p> Please verify your auth code </p>
                                <ReactCodeInput onChange={handlePinChange} isValid={isPinCodeValid} type='text' fields={6} {...props} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button onClick={verifySms} className="btn_register"> Verify Auth Code</button>

                            </div>
                        </div>
                    </div>
                }

                {step === 'stage3' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb-wrap reg">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active">Step 3</li>
                                            <li className="breadcrumb-item" aria-current="page"> Registration Success</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="form-wrap-titles">
                                <h1> Success</h1>
                                <p> Registration success please login to your account.</p>
                            </div>
                        </div>
                        <div className="row">

                        </div>
                    </div>
                }
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default Register;
