import React, { useState, useEffect } from "react";
import Sidebar from "../../components/CustomerPortal/SideBar";
import Header from "../../components/Header";
import Footer from "../../components/CustomerPortal/Footer";
import { NumericFormat } from "react-number-format";
import {
  getFirstLastNameFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../lib/common";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import ReactApexChart from "react-apexcharts";
import { API_ROUTES } from "../../utils/constants";
import { MdAttachMoney } from "react-icons/md";
import { FaBalanceScale, FaRegHandshake } from "react-icons/fa";
import { TbClockExclamation } from "react-icons/tb";

const Dashboard = () => {
  const [primary, setPrimary] = useState([]);
  const [column, setColumn] = useState([]);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
    setUserName(getFirstLastNameFromLocalStorage());
  }, []);

  const getData = async () => {
    try {
      const config = {
        Authorization: getTokenFromLocalStorage(),
      };

      const { data } = await axios.get(API_ROUTES.ACCOUNT_SUMMERY, {
        headers: config,
      });
      setPrimary(data?.data[0]);

      const summary = await axios.get(API_ROUTES.PRIMARY_ACCOUNT, {
        headers: config,
      });
      setColumn(summary.data.data[0]);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Bars
          height="100vh"
          width="80"
          color="#4d148c"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={loading}
        />
      </div>
    );
  }

  // Check if column data is available
  if (!column) {
    return <div>No data available</div>;
  }

  const chartData = {
    series: [
      column["0-5 days"],
      column["6-30 days"],
      column["31-60 days"],
      column["61-90 days"],
      column["91-180 days"],
      column["365+ days"],
    ],
    options: {
      chart: {
        type: "donut",
        height: 320,
        width: "100%",
      },
      stroke: {
        colors: ["transparent"],
        lineCap: "",
      },
      grid: {
        padding: {
          top: -2,
        },
      },
      labels: [
        "0-5 days",
        "6-30 days",
        "31-60 days",
        "61-90 days",
        "91-180 days",
        "365+ days",
      ],
      dataLabels: {
        enabled: false,
      },
      colors: [
        "#82E0AA",
        "#58D68D",
        "#2ECC71",
        "#F1C40F",
        "#E74C3C",
        "#C0392B",
      ],

      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif",
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
    },
  };

  return (
    <div style={{ maxWidth: "1536px", margin: "0 auto" }}>
      <Header name={{ name: "Welcome to React" }} />
      <Sidebar />
      <div className="p-4 sm:ml-64">
        <div className="pt-1 rounded bg-gray-100 dark:bg-gray-800 mt-10 pb-20">
          <h2 className="text-2xl pt-4 text-blue font-bold md:pl-12 md:pr-8">
            {` ${userName}`}
          </h2>
          <p className="text-slate-400 text-sm font-semibold pl-4 md:pl-12 md:pr-8 pb-2">
            Customer Code : {primary["Primary Account"]}
          </p>
          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <div className="w-full md:w-2/3 bg-slate-100 shadow pb-4 grid grid-flow-row md:grid-flow-col justify-stretch dark:bg-gray-800 md:justify-between">
              <div>
                <h2 className="text-2xl pt-4 pl-4 text-blue md:pl-12 md:pr-8">
                  Outstanding Age
                </h2>
                <p className="text-slate-400 text-sm pl-4 md:pl-12 md:pr-8 pb-6">
                  Currency {column["Currency"]}
                </p>
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="donut"
                  height={280}
                />
              </div>
              <div className="mt-20 pt-10 md:pt-10 pr-20 ">
                <table className="text-sm text-blue">
                  <tbody>
                    <tr>
                      <td className="text-center">0-5 days</td>
                      <td>
                        <NumericFormat
                          value={column["0-5 days"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-20"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">6-30 days</td>
                      <td>
                        <NumericFormat
                          value={column["6-30 days"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-20"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">31-60 days</td>
                      <td>
                        <NumericFormat
                          value={column["31-60 days"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-20"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">61-90 days</td>
                      <td>
                        <NumericFormat
                          value={column["61-90 days"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-20"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">91-180 days</td>
                      <td>
                        <NumericFormat
                          value={column["91-180 days"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-20"
                        />
                      </td>
                    </tr>
                    <tr className="">
                      <td className="text-center">365+ days</td>
                      <td>
                        <NumericFormat
                          value={column["365+ days"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-20"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="flex text-gray-500 capitalize text-blue text-2xl pt-2">
                        Total
                      </td>
                      <td className="text-2xl mt-2 text-blue pt-2">
                        <NumericFormat
                          value={column["Total"]}
                          displayType="text"
                          thousandSeparator={true}
                          prefix=""
                          decimalScale={2}
                          fixedDecimalScale={true}
                          className="grid place-content-end pl-10"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-full md:w-1/3 mt-0 md:mt-0 flex flex-col space-y-7 ml-10">
              <div className="flex">
                <div className="w-full max-w-sm max-h-sm shadow p-2 bg-slate-100 dark:bg-gray-800  hover:bg-slate-200 flex flex-col">
                  <a href="/open-invoice">
                    <MdAttachMoney className="h-6 w-6 text-pink-400" />
                    <div className="grid justify-start">
                      <h6 className="mt-1 text-slate-400 text-sm">
                        Total Outstanding
                      </h6>
                    </div>
                    <p className="text-3xl text-blue items-center justify-center flex">
                      <NumericFormat
                        value={primary["Current Account Balance"]}
                        displayType="text"
                        thousandSeparator={true}
                        prefix=""
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </a>
                </div>
              </div>
              <div className="flex">
                <div className="w-full max-w-sm max-h-sm shadow p-2 bg-slate-100 dark:bg-gray-800  hover:bg-slate-200 flex flex-col">
                  <a href="/open-invoice">
                    <TbClockExclamation className="h-6 w-6 text-green-400" />{" "}
                    <div className="grid justify-start">
                      <h6 className="mt-1 text-slate-400 text-sm">
                        Overdue Amount
                      </h6>
                    </div>
                    <p className="text-3xl text-blue items-center justify-center flex">
                      <NumericFormat
                        value={primary["DUE LKR"]}
                        displayType="text"
                        thousandSeparator={true}
                        prefix=""
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </p>
                  </a>
                </div>
              </div>
              {/* <div className="flex">
                <div className="w-full max-w-sm max-h-sm shadow p-2 bg-slate-100 dark:bg-gray-800  hover:bg-slate-200 flex flex-col">
                  <a href="">
                    <FaRegHandshake className="h-6 w-6 text-yellow-300" />{" "}
                    <div className="grid justify-start">
                      <h6 className="mt-1 text-slate-400 text-sm">
                        Disputed Invoices
                      </h6>
                    </div>
                    <p className="text-3xl text-blue items-center justify-center flex">
                      12
                    </p>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
