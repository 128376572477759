import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_ROUTES } from "../utils/constants";

const Careers = () => {
   const [apiError, setApiError] = useState(null);
   const {
      register,
      handleSubmit,
      formState: { errors },
  } = useForm();

  const onSubmit = async (form_data) => {
   try {
      const { data } = await axios({
          method: 'post',
          url: API_ROUTES.ContactUs,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: form_data
      });
   }
      catch (err) {
         setApiError('Some error occured during registration');
         //  console.log('Some error occured during signing in: ', err);
     }
  }
    return (
      <React.Fragment>
        <Header name={{ name: "Advantis Express", group: "home" }} />
        <div className="contentswrap career">
          <section
            className="banner-wrapper"
            style={{
              backgroundImage: `url(${"assets/img/career-banner.png"})`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="banner-title">
                    <h1>Join the FedEx Team</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap bann">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">HOME</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        CAREERS
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-titles text-center" data-aos="fade-down">
                  <h1>Be A Part of Something Bigger</h1>
                  <p>
                    Whether you're interested in joining our team of delivery
                    officers, cargo handlers, or wharf officers, or aiming to
                    build your career within one of our corporate teams, a
                    position with FedEx offers much more
                  </p>
                  <p>
                    You'll be connecting Sri Lankans to what matters most to
                    them—delivering birthday gifts from loved ones, assisting
                    businesses in meeting critical deadlines, linking healthcare
                    providers with life-saving medical shipments, or simply
                    ensuring that a package that brightens someone's day arrives
                    on time.
                  </p>
                  <p>
                    Whatever the role, if you believe you have what it takes to
                    help us create outstanding FedEx experiences, we want to
                    hear from you. Discover how you can contribute to the
                    success of one of the world's most well-known brands and
                    find out why Advantis Express has been placed amongst the
                    Best Workplaces in Sri Lanka ™ list.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="fexlife">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="fex-life-title" data-aos="fade-down">
                    <h3>Life at Advantis Express</h3>
                  </div>
                </div>
              </div>
              <div className="row g-0">
                {/* <div className="col-lg-6 col-md-12"  data-aos="fade-down">
                     <div className="cb-col-image" data-aos="fade-down">
                        <img src="assets/img/fedex-cares.png" alt=""  className="img-fluid"></img>
                     </div>
                  </div> */}
                <div className="col-md-12">
                  <div className="sub-titles text-center" data-aos="fade-down">
                    <h4>The Company</h4>
                    <p>
                      At Advantis Express, we firmly believe that a connected
                      world is a better world. We establish connections between
                      people and possibilities with our diverse portfolio of
                      transportation, e-commerce, and business
                      solutions.Currently, our networks span over 220 countries
                      and territories, linking to more than 99 percent of the
                      world’s GDP. FedEx, represented by Advantis Express,
                      consistently earns recognition as one of the most admired
                      brands globally and one of the best places to work. Our
                      people are the bedrock of our success and the key to our
                      future.
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="sub-titles text-center" data-aos="fade-down">
                    <h4>Our Philosophy</h4>
                    <p>
                      People-Service-Profit (PSP) is not just a philosophy but
                      the foundational business model upon which FedEx was
                      built. It remains a way of life for all our employees. By
                      prioritizing the well-being of our People, we deliver
                      outstanding customer Service, which, in turn, enables us
                      to earn a fair Profit. This Profit is then reinvested in
                      our People and our future.
                    </p>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="sub-titles text-center" data-aos="fade-down">
                    <h4>Our Culture</h4>
                    <p>
                      We believe in making every FedEx experience
                      outstanding—for the customer and for our team members
                    </p>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-12"  data-aos="fade-down">
                     <div className="cb-col-text tex" data-aos="fade-down">
                        <h4>The Company</h4>
                        <p>The Company At FedEx Express, we believe that a connected world is a better world. We connect people and possibilities with our diverse portfolio of transportation, e-commerce, and business solutions. Today, our networks reach more than 220 countries and territories, linking more than 99 percent of the world’s GDP. FedEx is consistently recognized as one of the most admired brands in the world and one of the best places to work. Our people are the foundation of our success and the key to our future.</p>
                        <a href="" className="btn-findmore">Find out more</a>
                     </div>
                  </div> */}
              </div>
            </div>
          </section>
          {/* <section className="benefits">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="benfit-title"  data-aos="fade-down">
                        <h2>Benefits of Working at Advantis Express​</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-md-3">
                     <div className="benefit-box"  data-aos="fade-down">
                        <img src="assets/img/be1.svg" alt=""></img>
                        <h4>Competitive pay for hourly and professional positions.</h4>
                     </div>
                  </div>
                  <div className="col-md-3">
                     <div className="benefit-box"  data-aos="fade-down">
                        <img src="assets/img/be2.svg" alt=""></img>
                        <h4>Opportunities to further your career and your education.</h4>
                     </div>
                  </div>
                  <div className="col-md-3">
                     <div className="benefit-box"  data-aos="fade-down">
                        <img src="assets/img/be3.svg" alt=""></img>
                        <h4>Work on a team that is as diverse as the communities we serve.</h4>
                     </div>
                  </div>
                  <div className="col-md-3">
                     <div className="benefit-box"  data-aos="fade-down">
                        <img src="assets/img/be4.svg" alt=""></img>
                        <h4>Flexible shifts that fit your lifestyle.</h4>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}
          <section className="current-openings">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="current-openings-title" data-aos="fade-down">
                    <h3>Current openings at Advantis Express</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="c-opening-row" data-aos="fade-down">
                    <a href="/career-details" className="c-opening-box">
                      <div className="c-openning-col1 c1">
                        <h4>Req ID: RC602293</h4>
                        <h6>Deputy Head – Regional 01 Sales</h6>
                      </div>
                      <div className="c-openning-col1 c2">
                        <h5>Location</h5>
                        <h6>Colombo 02</h6>
                      </div>
                      <div className="c-openning-col1 c3">
                        <h5>Employment</h5>
                        <h6>Full Time</h6>
                      </div>
                      <div className="c-openning-col1 c4">
                        <h5>Ends on</h5>
                        <h6>10 Octomber 2023</h6>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="applyform">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="apply-title text-center" data-aos="fade-down">
                    <h3>
                      Fill in your details and we will get back to you if there
                      are any openings
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-md-12">
                    <div className="apply-form-wrap" data-aos="fade-down">
                      <div className="contacts-info f-apply">
                        <div className="row">
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("first_name", {
                                required: "First Name is required",
                              })}
                              id="first_name"
                              placeholder="Enter First Name"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.first_name?.message && (
                              <small className="text-danger">
                                {errors.first_name.message}
                              </small>
                            )}
                            <label for="floatingInput">First name</label>
                          </div>
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("last_name", {
                                required: "Last Name is required",
                              })}
                              id=""
                              placeholder="Enter Last Name"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.last_name?.message && (
                              <small className="text-danger">
                                {errors.last_name.message}
                              </small>
                            )}
                            <label for="floatingInput">Last Name</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("contact_number", {
                                required: "Countact Number is required",
                              })}
                              id=""
                              placeholder="Enter Countact Number"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.contact_number?.message && (
                              <small className="text-danger">
                                {errors.contact_number.message}
                              </small>
                            )}
                            <label for="floatingInput">Contact number</label>
                          </div>
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("city", {
                                required: "Countact Number is required",
                              })}
                              id="city"
                              placeholder="Enter City"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.city?.message && (
                              <small className="text-danger">
                                {errors.city.message}
                              </small>
                            )}
                            <label for="floatingInput">City</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating mb-3 col-md-12">
                            <input
                              type="email"
                              className="form-control"
                              {...register("email", {
                                required: "Email is required",
                              })}
                              id=""
                              placeholder="Enter Email"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.email?.message && (
                              <small className="text-danger">
                                {errors.email.message}
                              </small>
                            )}
                            <label for="floatingInput">Email</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="qualifications-wrap">
                              <h3>Qualifications & Experience</h3>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating mb-3 Education">
                            <input
                              type="text"
                              className="form-control"
                              {...register("education", {
                                required: "Email is required",
                              })}
                              id=""
                              placeholder="Enter Education"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.education?.message && (
                              <small className="text-danger">
                                {errors.education.message}
                              </small>
                            )}
                            <label for="floatingInput">Education</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("position", {
                                required: "Email is required",
                              })}
                              id="position"
                              placeholder="Enter Position"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.position?.message && (
                              <small className="text-danger">
                                {errors.position.message}
                              </small>
                            )}
                            <label for="floatingInput">
                              Position you are interested in applying for
                            </label>
                          </div>
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("current_salary", {
                                required: "Enter Current Salary",
                              })}
                              id="position"
                              placeholder="Enter Position"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.current_salary?.message && (
                              <small className="text-danger">
                                {errors.current_salary.message}
                              </small>
                            )}
                            <label for="floatingInput">Current Salary</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("current_position", {
                                required: "Enter Current Position",
                              })}
                              id="current_position"
                              placeholder="Enter Position"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.current_position?.message && (
                              <small className="text-danger">
                                {errors.current_position.message}
                              </small>
                            )}
                            <label for="floatingInput">Current Position</label>
                          </div>
                          <div className="form-floating mb-3 col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              {...register("working_experience", {
                                required: "Working Experience is required",
                              })}
                              id="working_experience"
                              placeholder="Enter Working Experience"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.working_experience?.message && (
                              <small className="text-danger">
                                {errors.working_experience.message}
                              </small>
                            )}
                            <label for="floatingInput">
                              Work Experience
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-floating mb-3">
                            <input
                              type="file"
                              className="form-control"
                              {...register("resume", {
                                required: "Resume is required",
                              })}
                              id="resume"
                              placeholder="Attache Resume"
                            />
                            {/* errors will return when field validation fails  */}
                            {errors.resume?.message && (
                              <small className="text-danger">
                                {errors.resume.message}
                              </small>
                            )}
                            <label for="floatingInput">Resume</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button type="submit" className="btn_register">
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
  
  export default Careers;