import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const CustomscodesFaq = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Advantis Express' , group:'home' }}   />
          <div className="contentswrap">
            <section className="banner-wrapper" style={{backgroundImage: `url(${"assets/img/faq-banner.png"})`,}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-title">
                            <h1>Frequently asked questions</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-wrap faq2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"><a href="/faq">FAQ</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Customs codes</li>
                            </ol>
                        </nav>
                        <h2 className="faqtitle">Common questions asked by customers</h2>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-md-4">
                    {/* <!-- mobile search --> */}
                    <div className="faq-search-mobile">
                        <div className="row">
                            <div className="col-12">
                                <div className="search-form">
                                    <input type="text" className="form-control" placeholder="Search your question"></input>
                                    <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- mobile search end --> */}
                    <div className="faq-sidebar">                     
                        <div className="faqwrapper">
                            <div className="faq-link-wrap" data-aos="fade-down">
                            <h2>Customs codes</h2>
                            <ul>
                                <li> <a href="#f1">What is a harmonized or HS code?</a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="faq-content-col">
                        <div className="faq-search-wrap">
                        <div className="faq-search-dk">
                            <div className="row">
                                    <div className="col-12">
                                        <div className="search-form">
                                        <input type="text" className="form-control" placeholder="Search your question"></input>
                                        <button type="submit" className="btn-search"><img src="assets/img/search-icon.png" alt=""></img></button>
                                        </div>
                                    </div>
                            </div>
                        </div>                     
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-contents faqlist" data-aos="fade-down">
                                        <h2>Customs codes</h2>  
                                        <div className="quick-faq">
                                        <ul>
                                            <li><a href=""style={{color:'#333',fontSize:28}}>What is a harmonized or HS code?</a></li>
                                            <p>A harmonized code is part of an internationally standardized system of names and numbers for classifying traded products – these codes are developed and maintained by the World Customs Organizations (WCO).
                                            This system allows all countries/territories to be on the same page when classifying products before export and import. Harmonized codes may be referred to in more than one way, depending on where the shipment is going and who is responsible for it – for example, they’re also known as HC, HTS, commodity code or tariff, depending on the country/territory.
                                            Finding the correct commodity code is crucial for importers and exporters all over the world. It not only helps to identify what kind of products are being shipped, it plays a vital role in the taxation systems and controls.
                                            <br></br>
                                            Here’s how the HS code is shown and a few other key points:  
                                            <br></br>
                                                •	The first 6 digits are the same in 183 countries/territories
                                                <br></br>
                                                •	The remaining digits are generally country/territory specific and define tax or preferential rates
                                                <br></br>
                                                •	European export declarations generally require 8 digits
                                                <br></br>
                                                •	European import declarations generally require 10 digits
                                                <br></br>
                                                •	U.S. declarations require 10 digits
                                                <br></br>
                                                •	The description of the goods defines the HS code
                                                <br></br>
                                                •	The HS code, along with value and currency, determines duty and tax rates

                                            </p>
                                            <br></br>
                                        </ul>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        
                                        </div>                                 
                                    </div>                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
    );
  }
  
  export default CustomscodesFaq;