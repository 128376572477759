import React from "react";

const Sidebar = () => {
  return (
    <div className=" p-4  bg-gray w-full ">
      <div className="mb-4">
        <img
          src="image.jpg"
          alt="Image"
          className="w-full h-7 object-cover rounded"
        />
      </div>
      <div className="space-y-4">
        <a
          href="/dashboard"
          className=" py-3 flex justify-center text-white hover:underline bg-purple  hover:bg-org border border-gray "
        >
          DASHBOARD
        </a>

        <a
          href="/open-invoice"
          className=" py-3 flex justify-center text-white hover:underline bg-purple  hover:bg-org border border-gray "
        >
          OPEN INVOICES
        </a>
        <a
          href="/past-due-nvoices"
          className=" py-3 flex justify-center text-white hover:underline bg-purple  hover:bg-org border border-gray "
        >
          PAST DUE
        </a>
        <a
          href="/Paid-closed-invoices"
          className=" py-3 flex justify-center text-white hover:underline bg-purple  hover:bg-org border border-gray "
        >
          PAID CLOSE
        </a>
        <a
          href="/in-dispute-invoices"
          className=" py-3 flex justify-center text-white hover:underline bg-purple  hover:bg-org border border-gray "
        >
          IN DISPUTE
        </a>
        <a
          href="/settings"
          className=" py-3 flex justify-center text-white hover:underline bg-purple  hover:bg-org border border-gray "
        >
          SETTINGS
        </a>
      </div>
    </div>
  );
};
export default Sidebar;