import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const SecurityPrivacy = () => {
    return (
      <React.Fragment>
        <Header name={{ name: "Advantis Express", group: "home" }} />
        <div className="contentswrap">
          <section
            className="banner-wrapper"
            style={{
              backgroundImage: `url(${"assets/img/aboutus-banner.png"})`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="banner-title">
                    <h1> Privacy Policy </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap bann">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">HOME</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                    Privacy Policy
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-titles privacy" data-aos="fade-down">
                <h1>Privacy Policy for Lead Handling</h1>
    <p>This Privacy Policy outlines how we collect, use, and protect the personal information of individuals ("leads") who engage with our marketing campaigns, request information, or interact with our sales teams.</p>

    <h2>1. Information We Collect</h2>
    <p>We collect personal information that may include, but is not limited to, names, email addresses, phone numbers, business details, and other relevant data. This information is provided by individuals through forms, surveys, or direct interactions with our sales and marketing teams.</p>

    <h2>2. How We Use Your Information</h2>
    <p>We use the personal data provided by leads for the following purposes:</p>
    <ul>
        <li>To communicate with you regarding our services and promotions.</li>
        <li>To personalize marketing communications and ensure they are relevant to your business needs.</li>
        <li>To improve and develop new offerings based on feedback and interaction.</li>
        <li>To comply with legal obligations and protect our legal interests.</li>
    </ul>

    <h2>3. Data Sharing</h2>
    <p>We may share your personal data with:</p>
    <ul>
        <li><strong>Third-party Service Providers:</strong> We may engage third-party vendors to assist in processing and managing data (e.g., CRM systems, email marketing platforms) on our behalf, ensuring that they comply with data protection standards.</li>
        <li><strong>Legal Requirements:</strong> If required by law, we may disclose your data to comply with legal obligations, respond to lawful requests, or protect our rights.</li>
    </ul>

    <h2>4. Data Protection</h2>
    <p>We implement appropriate security measures to protect your personal data from unauthorized access, use, or disclosure. This includes encryption, access controls, and regular monitoring of our systems.</p>

    <h2>5. Your Rights</h2>
    <p>You have the right to access, correct, or request the deletion of your personal data. If you no longer wish to receive marketing communications, you can opt out by following the instructions in the communications or by contacting us directly.</p>

    <h2>6. Data Retention</h2>
    <p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected or as required by law. Once no longer needed, your data will be securely deleted or anonymized.</p>

    <h2>7. Contact Information</h2>
    <p>If you have questions or concerns about how your personal data is being handled, please contact our IT support team:</p>
    <p><strong>Contact:</strong> 077 180 7541</p>
                </div>
              </div>
            </div>
          </section>
       
          {/* <section className="additional">
            <div className="container">
               <div className="row">
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-1.png" alt=""></img>   
                        </div>
                        <h4>Business & <br></br>E-Commerce</h4>
                        <a href="" className="btn-border">Grow your business</a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-2.png" alt=""></img>   
                        </div>
                        <h4>Ship & Save up to 45% <br></br>for 6 months</h4>
                        <a href="" className="btn-border">Open an account</a>
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="additional-box" data-aos="fade-down">
                        <div className="addimage">
                           <img src="assets/img/wu-3.png" alt=""></img>   
                        </div>
                        <h4>International Shipping<br></br> Expertise</h4>
                        <a href="" className="btn-border">Find out more</a>
                     </div>
                  </div>
                  <div className="col-md-12" data-aos="fade-down">
                     <p className="term">*Some restrictions apply. See the Terms and Conditions section of the FedEx Service Guide and visit fedex.ca for details.</p>
                  </div>
               </div>
            </div>
         </section> */}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
  
  export default SecurityPrivacy;
  