import Header from "../components/Header";
import Footer from "../components/Footer";
import React from 'react';

const RequestingStationery = () => {
    return ( 
    <React.Fragment>
          <Header name={{ name: 'Welcome to React' , group:'support' }} />
          <div class="container">
         <div class="row">
            <div class="col-md-12">
               <div class="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Requesting Stationery</li>
                     </ol>
                  </nav>
               </div>
            </div>
            <div class="col-md-12">
               <div class="form-wrap-titles">
                  <h1>Requesting Stationery</h1>
               </div>
               <div class="form-wrap pickups">
                  <div class="row">
                     <div class="form-floating mb-3 col-md-12" >
                        <input type="text" class="form-control" id="" placeholder="Pickup"/>
                        <label for="floatingInput">Company Name</label>
                     </div>
                  </div>
                  <div class="row">
                     <div class="form-floating mb-3 col-md-12" >
                        <input type="text" class="form-control" id="" placeholder="Pickup"/>
                        <label for="floatingInput">Delivery Address*</label>
                     </div>
                  </div>
                  <div class="row">
                     <div class="form-floating mb-3 col-md-12" >
                        <input type="text" class="form-control" id="" placeholder="Pickup"/>
                        <label for="floatingInput">City</label>
                     </div>
                  </div>
                  <div class="contacts-info">
                     <h3>Contact details</h3>
                     <div class="row">
                        <div class="form-floating mb-3 col-md-12" >
                           <input type="text" class="form-control" id="" placeholder="Pickup"/>
                           <label for="floatingInput">Contact Person*</label>
                        </div>
                     </div>
                     <div class="row">
                        <div class="form-floating mb-3 col-md-6" >
                           <input type="text" class="form-control" id="" placeholder="Pickup"/>
                           <label for="floatingInput">Contact Number*</label>
                        </div>
                        <div class="form-floating mb-3 col-md-6" >
                           <input type="text" class="form-control" id="" placeholder="Pickup"/>
                           <label for="floatingInput">Secondary Contact Number</label>
                        </div>
                     </div>
                     <div class="row">
                        <div class="form-floating mb-3 col-md-12" >
                           <input  type="date" class="form-control" id="" placeholder="Pickup"/>
                           <label for="floatingInput">Delivery date*</label>
                        </div>
                     </div>
                  </div>
                  <div class="contacts-info pkage2">
                     <h3>Type of packages</h3>
                     <div class="row mb-3">
                        <div class="col-md-6">
                           <h4>Package type</h4>
                        </div>
                        <div class="col-md-6">
                           <h5>Quantity</h5>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <div class="package-row-wrap">
                              <div class="row g-1">
                                 <div class="col-md-1  p-2">
                                    <div class="pk-no">
                                       <h4> #1</h4>
                                    </div>
                                 </div>
                                 <div class="col-md-1 p-2 gray">
                                    <div class="pk-thumb">
                                       <img src="assets/img/pk1.png" alt="" class="img-fluid"/>
                                    </div>
                                 </div>
                                 <div class="col-md-8 p-2 gray">
                                    <div class="pk-title">
                                       <h6>FedEx Envelope</h6>
                                       <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper</p>
                                    </div>
                                 </div>
                                 <div class="col-md-2 p-2 gray">
                                    <div class="pk-counter">
                                       <div class="nice-number"> 
                                          <input type="number" value="1" min="1"/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="package-row-wrap">
                              <div class="row g-1">
                                 <div class="col-md-1  p-2">
                                    <div class="pk-no">
                                       <h4> #2</h4>
                                    </div>
                                 </div>
                                 <div class="col-md-1 p-2 gray">
                                    <div class="pk-thumb">
                                       <img src="assets/img/pk2.png" alt="" class="img-fluid"/>
                                    </div>
                                 </div>
                                 <div class="col-md-8 p-2 gray">
                                    <div class="pk-title">
                                       <h6>FedEx Envelope</h6>
                                       <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper</p>
                                    </div>
                                 </div>
                                 <div class="col-md-2 p-2 gray">
                                    <div class="pk-counter">
                                       <div class="nice-number"> 
                                          <input type="number" value="1" min="1"/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="package-row-wrap">
                              <div class="row g-1">
                                 <div class="col-md-1  p-2">
                                    <div class="pk-no">
                                       <h4> #3</h4>
                                    </div>
                                 </div>
                                 <div class="col-md-1 p-2 gray">
                                    <div class="pk-thumb">
                                       <img src="assets/img/pk3.png" alt="" class="img-fluid"/>
                                    </div>
                                 </div>
                                 <div class="col-md-8 p-2 gray">
                                    <div class="pk-title">
                                       <h6>FedEx Envelope</h6>
                                       <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper</p>
                                    </div>
                                 </div>
                                 <div class="col-md-2 p-2 gray">
                                    <div class="pk-counter">
                                       <div class="nice-number"> 
                                          <input type="number" value="1" min="1"/>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>                   
                  </div>
                  <div class="row">
                    <div class="col-12">
                        <div class="addpackage-wrap">
                            <button type="button" class="addpackage" data-bs-toggle="modal" data-bs-target="#addpackage-popup">+ Add more packages</button>  
                      </div>
                    </div>
                  </div>
                  <div class="row">
                        <div class="col-12">
                           <button type="submit" class="btn_register">SUBMIT REQUEST</button>  
                        </div>
                     </div>
               </div>
            </div>
         </div>
      </div>
      <div class="modal fade" id="addpackage-popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Select your package type which will fit in our package</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                            <div class="pop-row-wrap">
                                <div class="popup-thumb">
                                    <img src="assets/img/pop-package-image.jpg" alt=""/>
                                </div>
                                <div class="popup-row-title">
                                    <h2>FedEx Envelope</h2>
                                    <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo </p>
                                </div>
                                <div class="dimention-wrap">
                                    <h3>DIMENTIONS</h3>
                                    <h4>5" x 5" x 5" CM</h4>
                                </div>
                                <div class="package-select">
                                    <button class="btn-select-package">Select Package</button>
                                </div>
                            </div>
                            <div class="pop-row-wrap">
                                <div class="popup-thumb">
                                    <img src="assets/img/pop-package-image.jpg" alt=""/>
                                </div>
                                <div class="popup-row-title">
                                    <h2>FedEx Envelope</h2>
                                    <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo </p>
                                </div>
                                <div class="dimention-wrap">
                                    <h3>DIMENTIONS</h3>
                                    <h4>5" x 5" x 5" CM</h4>
                                </div>
                                <div class="package-select">
                                    <button class="btn-select-package">Select Package</button>
                                </div>
                            </div>
                            <div class="pop-row-wrap">
                                <div class="popup-thumb">
                                    <img src="assets/img/pop-package-image.jpg" alt=""/>
                                </div>
                                <div class="popup-row-title">
                                    <h2>FedEx Envelope</h2>
                                    <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo </p>
                                </div>
                                <div class="dimention-wrap">
                                    <h3>DIMENTIONS</h3>
                                    <h4>5" x 5" x 5" CM</h4>
                                </div>
                                <div class="package-select">
                                    <button class="btn-select-package">Select Package</button>
                                </div>
                            </div>
                            <div class="pop-row-wrap">
                                <div class="popup-thumb">
                                    <img src="assets/img/pop-package-image.jpg" alt=""/>
                                </div>
                                <div class="popup-row-title">
                                    <h2>FedEx Envelope</h2>
                                    <p>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo </p>
                                </div>
                                <div class="dimention-wrap">
                                    <h3>DIMENTIONS</h3>
                                    <h4>5" x 5" x 5" CM</h4>
                                </div>
                                <div class="package-select">
                                    <button class="btn-select-package">Select Package</button>
                                </div>
                            </div>
                        </div>                
                    </div>
                </div>
            </div>
      
          <Footer/>
    </React.Fragment>
    );
  }
  
  export default RequestingStationery;