import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/CustomerPortal/SideBar";
import Footer from "../../components/CustomerPortal/Footer";
import Header from "../../components/Header";
import DataTable from "react-data-table-component";
import { API_ROUTES } from "../../utils/constants";
import { getTokenFromLocalStorage } from "../../lib/common";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { FaEye, FaSearch } from "react-icons/fa";
import { TbCreditCardPay } from "react-icons/tb";

const OpenInvoice = () => {
  const [list, setList] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const currentDate = moment();
  const [todayDate, setTodayDate] = useState(currentDate.format("DD MMM YYYY"));

  const [endDate, setEndDate] = useState(currentDate.format("DD/MM/YYYY"));
  const [startDate, setStartDate] = useState(
    currentDate.subtract(45, "days").format("DD/MM/YYYY")
  );

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "#223336",
        backgroundColor: "#e7eef0",
        textAlign: "center",
      },
    },
    rows: {
      style: {
        textAlign: "center",
      },
      stripedStyle: {
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toDateChange = () => {
    setDataLoad(false);
    fetchData();
  };

  const handleViewInvoice = (invoiceNumber) => {
    navigate(`/invoice-details-view?invoice-number=${invoiceNumber}`);
  };
  const handlePayInvoice = (invoiceNumber) => {
    localStorage.setItem("openInvoice", JSON.stringify([invoiceNumber]));
    navigate(`/payment-cart`);
  };
 const handlePayInvoices = () => {
   const selectedInvoiceNumbers = selectedRows.map((row) => row["Invoice No"]);
   localStorage.setItem(
     "selectedInvoiceNumbers",
     JSON.stringify(selectedInvoiceNumbers)
   );
   navigate(`/payment-cart`);
 };


  const fetchData = async () => {
    try {
      const config = {
        Authorization: getTokenFromLocalStorage(),
      };

      let value = {
        FromDate: moment(startDate).format("DD-MM-YYYY"),
        ToDate: moment(endDate).format("DD-MM-YYYY"),
        IsPaid: "N",
      };
      const response = await axios({
        method: "post",
        url: API_ROUTES.INVOICE_LIST,
        data: value,
        headers: config,
      });
      const sortedData = response.data?.data.sort(
        (a, b) => new Date(a["Due Date"]) - new Date(b["Due Date"])
      );
      setList(sortedData || []);
      setList(response.data?.data || []);
      setDataLoad(response.data.meta?.status);
      setInvoiceLoading(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const calculateDueDays = (dueDate) => {
    const now = moment();
    const due = moment(dueDate);
    return now.diff(due, "days");
  };
  const numberFormat = (number) => {
    return (
      <NumericFormat
        value={number}
        displayType={"text"}
        thousandSeparator={true}
        prefix={""}
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  };

  const columns = [
    {
      name: "Invoice no",
      selector: (row) => row["Invoice No"],
    },
    {
      name: "Invoice Date",
      selector: (row) => format(new Date(row["Invoice Date"]), "dd MMM yyyy "),
    },
    {
      name: "Due Date",
      selector: (row) => format(new Date(row["Due Date"]), "dd MMM yyyy "),
    },
    // {
    //   name: "Account No",
    //   selector: (row) => row["Account No"],
    // },
    {
      name: "Due Days",
      selector: (row) => calculateDueDays(row["Due Date"]),
      sortable: true,
    },
    {
      name: "Total USD",
      selector: (row) => numberFormat(row["Original Charges USD"]),
      cell: (row) => (
        <div style={{ textAlign: "right" }}>
          {numberFormat(row["Original Charges USD"])}
        </div>
      ),
      right: true,
      style: {
        justifyContent: "flex-end",
      },
    },
    {
      name: "Total LKR",
      selector: (row) => numberFormat(row["Original Charges LKR"]),
      cell: (row) => (
        <div style={{ textAlign: "right" }}>
          {numberFormat(row["Original Charges LKR"])}
        </div>
      ),
      right: true,
      style: {
        justifyContent: "flex-end",
      },
    },
    {
      name: "Balance Due",
      selector: (row) => numberFormat(row["Balance Due LKR"]),
      cell: (row) => (
        <div style={{ textAlign: "right" }}>
          {numberFormat(row["Balance Due LKR"])}
        </div>
      ),
      right: true,
      style: {
        justifyContent: "flex-normal ",
      },
    },
    // {
    //   name: "Payment Status",
    //   selector: (row) => row["Payment Status"],
    // },

    {
      name: "Actions",
      cell: (row) => (
        <div className=" ">
          <button
            onClick={() => handleViewInvoice(row["Invoice No"])}
            className="btn  rounded mr-2 text-xs bg-btnGreen  px-1 py-0 "
          >
            {/* <FaEye className="text-green-400 size-4" /> */}View
          </button>

          {row.dispute != "n" ? (
            <button
              onClick={() => handlePayInvoice(row)}
              className="btn  rounded text-xs  bg-btnYellow  px-1 py-0"
            >
              {/* <TbCreditCardPay className="text-orange-500 size-4" /> */}Pay
            </button>
          ) : (
            <button className="btn bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 text-white font- py-2 px-1 shadow-md lg:rounded-lg ">
              Disputed
            </button>
          )}
        </div>
      ),
    },
  ];

  const handleChange = (state) => {
    setSelectedRows(state.selectedRows);
    localStorage.setItem("openInvoice", JSON.stringify(state.selectedRows));
  };

  return (
    <div style={{ maxWidth: "1536px", margin: "0 auto" }}>
      <div className="pb-5">
        <Header name={{ name: "Welcome to React" }} />
      </div>
      <Sidebar />
      <div className="p-4 sm:ml-64">
        <h3 className="text-4xl text-blue pt-14 md:pl-20 md:pr-20 font-bold flex justify-center">
          OPEN INVOICES
        </h3>
        <div className="mb-8 pr-3"></div>

        <div className="p-4 rounded-lg">
          <div className="flex justify-start mb-4">
            <h4 className="text-m text-blue   ">
              Open Invoices as at, {todayDate}
            </h4>
          </div>
          {/* <div className="mb-8 pr-3 flex flex-wrap items-center">
            <div className="flex items-center">
              <label className="mr-2">From :</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy/MMM/dd"
                className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="flex items-center ml-10 mr-10">
              <label className="mr-2 pl-2">To :</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy/MMM/dd"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <button onClick={toDateChange} className="bg-indigo-600 p-2">
              <FaSearch className="text-slate-300 size-5" />
            </button>
          </div> */}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="text-xs text-gray-700 uppercase dark:text-gray-400">
              <div className="flex flex-row justify-center items-center">
                <Bars
                  height="80"
                  width="80"
                  color="#4d148c"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={!dataLoad}
                />
              </div>
              <DataTable
                visible={list.length !== 0}
                columns={columns}
                data={list}
                selectableRows
                onSelectedRowsChange={handleChange}
                customStyles={tableCustomStyles}
              />
            </div>
          </div>
          <div className="flex space-x-1 py-8 pr-5 justify-end">
            <button
              disabled={selectedRows.length === 0}
              onClick={handlePayInvoices}
              className={`text-purple-600 hover:text-white border border-purple-600 ring-2 ring-purple-600 hover:bg-purple-600 focus:ring-4 focus:outline-none focus:ring-purple-600 font-medium rounded-2xl text-sm px-3 py-2.5 text-center me-2 mb-2 dark:border-purple-600 dark:text-purple-600 dark:hover:text-white dark:hover:bg-purple-600 dark:focus:ring-purple-600 ${
                selectedRows.length === 0
                  ? "cursor-not-allowed opacity-200"
                  : "cursor-pointer"
              }`}
            >
              Pay Invoices
            </button>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default OpenInvoice;

// {FromDate: "2024-04-11", ToDate: "2024-05-26", IsPaid: "N"}
// FromDate
// :
// "2024-04-11"
// IsPaid
// :
// "N"
// ToDate
// :
// "2024-05-26"

// {FromDate: "2024-04-11", ToDate: "2024-05-26", IsPaid: "N"}
// FromDate
// :
// "2024-04-11"
// IsPaid
// :
// "N"
// ToDate
// :
// "2024-05-26"
